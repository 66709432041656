@import '~@/platform/desktop/styles/set.scss';
.cb-slideshowbox {
  $height: calc(96vh - 76px);
  width: 100%;
  height: $height;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  .item {
    height: $height;
    a {
      display: block;
      height: $height;
    }
    img {
      width: 100%;
      height: $height;
      object-fit: cover;
    }
    span {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      // opacity: 0;
      // z-index: 0;
      //   -webkit-backface-visibility: hidden;
      font-size: 0;
    }
  }
  .slick-slider {
    font-size: 0;
    .slick-dots {
      position: absolute;
      width: 100%;
      left: 0;
      height: 100px;
      bottom: 0;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-size: 0;
      background: linear-gradient(transparent, rgba(20, 20, 20, 0.2));
      li {
        display: inline-block;
        margin: 20px 5px 10px;
        button {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          border: 2px solid #fff;
          @include transition(all, 0.5s, cubic-bezier(0.165, 0.84, 0.44, 1));
          cursor: pointer;
          box-sizing: content-box;
        }
        &.slick-active {
          button {
            width: 33px;
            background: #fff;
          }
        }
      }
    }
  }
  .slick-active {
    z-index: 1;
  }
}

.cb-slideshow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  li {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    a {
      display: block;
      height: 100%;
    }
    span {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      opacity: 0;
      z-index: 0;
      //   -webkit-backface-visibility: hidden;
      font-size: 0;
    }
    &.active {
      z-index: 2;
      span {
        // -ms-transform-style: none;
        animation: imageAnimation 6s linear infinite 0s;
        // -ms-transform: none;
      }
    }
    &.fade {
      span {
        // -ms-transform-style: none;
        animation: fadeAnimation 6s linear infinite 0s;
        // -ms-transform: none;
      }
    }
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  10% {
    opacity: 1;
  }
  //   30% {
  //     opacity: 0.8;
  //   }
  70% {
    opacity: 1;
    transform: scale(1.07);
    animation-timing-function: ease-out;
  }
  95% {
    opacity: 1;
    transform: scale(1.1);
  }
  //   98% {
  //     opacity: 0.5;
  //     transform: scale(1.5);
  //   }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

@keyframes fadeAnimation {
  0% {
    opacity: 0.8;
    transform: scale(1.1);
  }
  20% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
  60% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
  }
}

/* Show at least something when animations not supported*/
.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}

.slide-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(transparent, rgba(20, 20, 20, 0.1));
  text-align: center;
  li {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 20px 5px 10px;
    border-radius: 8px;
    border: 2px solid #fff;
    @include transition(all, 0.5s, cubic-bezier(0.165, 0.84, 0.44, 1));
    cursor: pointer;
    &.active {
      width: 33px;
      background: #fff;
    }
  }
}
@media (max-width: $screen-lg-min) {
}
@media (max-width: $screen-md-min) {
}
