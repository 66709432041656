@import '~@/platform/desktop/styles/set.scss';

.attendtion {
  margin-top: 25px;
  color: #aeaeae;
  font-size: 14px;
  line-height: 30px;
  img {
    vertical-align: middle;
    margin-right: 7px;
    margin-top: -3px;
  }
}
