.friendly-link {
  li {
    $mr: 25px;
    display: inline-block;
    position: relative;
    width: calc((100% - #{$mr}* 3) / 4);
    line-height: 40px;
    margin-right: $mr;
    &:nth-child(4n) {
      margin-right: 0;
    }
    a {
      padding-left: 20px;
      color: #2a2a2a;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 5px;
      height: 5px;
      margin-top: -3px;
      border-radius: 50%;
      background: #000;
    }
  }
}
