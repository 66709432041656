@import '~@/platform/desktop/styles/set.scss';

.innercollection {
  margin-bottom: 110px;
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    li {
      position: relative;
      left: 0;
      width: 220px;
      height: 430px;
      background-size: auto 104%;
      transform: none;
      @extend %vertival;
      box-reflect: below 0 -webkit-linear-gradient(transparent 60%, rgba(0, 0, 0, 0.2));
      -webkit-box-reflect: below 0 -webkit-linear-gradient(transparent 60%, rgba(0, 0, 0, 0.2));
    }
    .firstli {
      display: none;
    }
    .sixthli {
      display: block;
    }
  }
}

.stydydetail {
  %border {
    border-bottom: 1px solid #c1d0db;
  }
  #photoviewer1 {
    .topbox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      .imgstyle {
        flex-shrink: 0;
        width: 383px;
        height: 515px;
        margin-right: 60px;
      }
      .title {
        margin: 0;
        h1 {
          text-align: left;
          font-weight: normal;
        }
      }
      .cont {
        margin-top: 0;
      }
    }
  }

  //   .title-img {
  //     margin-top: 40px;
  //     margin-bottom: 20px;
  //     font-size: 16px;
  //     color: #727171;
  //     line-height: 1.5;
  //     font-weight: 700;
  //   }
}
