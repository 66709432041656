@import '~@/platform/desktop/styles/set.scss';

//图文详情
.maindetail {
  position: relative;
  width: $detail-width;
  margin: 0 auto;
  text-align: justify;
  padding-bottom: 30px;
  z-index: 1;
  .title {
    margin: 20px 0;
    line-height: 50px;
    color: $color-main;
    text-align: center;
    h1 {
      font-size: 24px;
      line-height: 1.5;
      //   font-weight: bold;
    }
  }
  .tip {
    font-size: 14px;
    color: #aaa;
    text-align: center;
    line-height: 2;
    margin-top: 20px;
    span {
      margin: 0 10px;
    }
  }
  .abstract {
    padding: 20px 0 0;
    p {
      font-size: 16px;
      color: #727171;
      line-height: 30px;
    }
  }

  .cont {
    margin-top: 30px;
    // min-height: 300px;
    line-height: 1.625;
    font-size: 16px;
    color: #666;
    &.video-cont {
      min-height: 10px;
    }
    p {
      margin: 20px 0;
      //   text-indent: 2em;
    }
    iframe {
      width: 1250px;
      height: 800px;
      border: 0;
      margin-left: -193px;
    }
    img,
    video,
    audio {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 5px auto;
    }
    audio {
      width: 100%;
      height: 54px;
      outline: 0;
    }
    video {
      width: $detail-width !important;
      height: auto !important;
      background-color: #000;
    }
    embed {
      display: block;
      margin: auto;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
    }
    table {
      max-width: 100%;
      margin: 5px auto;
      border-collapse: collapse;
      text-align: center;
      tr {
        margin: 0;
        td {
          padding: 5px 10px;
          border: 1px solid #a9a9a9;
        }
      }
    }
    ul,
    ol {
      margin-left: 20px;
    }
    ul li {
      list-style-type: disc;
    }
    ol li {
      list-style-type: decimal;
    }
    a {
      color: $color-main;
    }
  }
}

.maindetail + .detail-share {
  width: 1000px;
  margin: 30px auto;
}

// 文物详情
.collectdetail {
  .imgstyle {
    display: inline-block;
    width: 400px;
    height: 400px;
    margin-right: 50px;
    vertical-align: top;
    padding: 10px;
    border: 1px solid #bfd7e3;
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
    .threed {
      right: 20px;
      top: 20px;
    }
  }
  .maindetail {
    display: inline-block;
    width: calc(100% - 450px);
    .title {
      margin: 0;
      padding-bottom: 20px;
      line-height: inherit;
      text-align: left;
      border-bottom: 1px solid #e8ebed;
    }
  }
  &.wcdetail {
    .imgstyle {
      width: 500px;
      height: 350px;
      margin-right: 40px;
    }
    .maindetail {
      width: calc(100% - 540px);
      .cont {
        p {
          text-indent: 0;
        }
      }
    }
  }
  &.wbdetail {
    .imgstyle {
      width: 400px;
      height: 500px;
    }
  }
}

// 视频集锦详情
.maindetail {
  .video-show {
    .video-list {
      padding: 0 70px;
      & > li {
        font-size: 0;
        margin: 20px 0;
        video {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.fujian {
  margin-top: 20px;
  & > span {
    font-size: 20px;
  }
  .fj-tip {
    color: $color-main;
    margin: 10px 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    span {
      width: 100%;
      overflow: hidden;
      word-break: break-all;
    }
    .down {
      flex-shrink: 0;
      padding: 0 10px;
      background-color: $color-main;
      color: #fff;
      height: 30px;
      line-height: 30px;
      margin-left: 30px;
      font-size: 14px;
      letter-spacing: 2px;
      cursor: pointer;
    }
  }
}
.detail-iframe {
  width: 1250px;
  height: 800px;
  border: 0;
  margin-left: -75px;
}
