@import '~@/styles/reset.scss';
@import 'set.scss';

html,
body {
  min-width: 1340px;
}

//适配手机版
/* .wapbody {
  min-width: auto !important;
  width: 1340px !important;
  overflow-x: hidden !important;
  min-height: 2700px !important;
} */

body {
  font: #{$font-size-base}/#{$line-height-base} $font-family;
}

.allcenter {
  width: $index-allcenter-width-sm;
  margin-left: auto;
  margin-right: auto;
}

/*

    滚动条

 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #969696;
}
