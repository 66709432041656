@import '~@/platform/desktop/styles/set.scss';
.introduction-i,
.openinfo,
.snews {
  height: 360px;
  @include background();
  background-image: url('#{$g-imgsrc}/survey/introduction-bg.jpg');
}
.openinfo {
  background-image: url('#{$g-imgsrc}/survey/openinfo-bg.jpg');
}
.snews {
  background-image: url('#{$g-imgsrc}/survey/snews-bg.jpg');
}
.introduction-cont {
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  padding: 65px 50px 60px;
  height: 100%;
  box-sizing: border-box;
  .title {
    max-width: 410px;
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: normal;
    @include cl-fs-linh(#fff, 18px, 1.2);
    @include ellipsis();
  }
  .cont {
    // max-width: 360px;
    max-width: 400px;
    max-height: 160px;
    text-align: justify;
    text-indent: 2em;
    // margin-bottom: 35px;
    margin-bottom: 15px;
    @include cl-fs-linh(#fff, 14px, 1.6);
    overflow: auto;
  }
}
.introduction-cont,
.snews {
  .viewmore {
    display: inline-block;
    max-width: 56px;
    padding: 5px 7px;
    border: 1px solid #88a6d0;
    border-radius: 5px;
    @include cl-fs-linh(#88a6d0, 14px, 1);
    // opacity: 0.6;
  }
}
.snews {
  .viewmore {
    color: #666;
    border-color: #666;
  }
}

.openinfo {
  position: relative;
  .top {
    // padding: 70px 0 63px 376px;
    position: absolute;
    right: 114px;
    width: 600px;
    top: 70px;
  }
  .tip {
    @include cl-fs-linh(#fff, 18px, 1.5);
  }
  .time {
    display: flex;
    margin-top: 30px;
    margin-right: 120px;
    justify-content: space-between;
    align-items: center;
    width: 604px;

    li {
      position: relative;
      text-align: center;
      .t {
        font-family: num;
      }
      &::after {
        content: '~';
        position: absolute;
        top: 10px;
        right: -97px;
        display: block;
        font-family: num;
        @include cl-fs-linh(#fff, 26px, 1.2);
      }
      &:last-child::after {
        display: none;
      }
    }
    .t {
      @include cl-fs-linh(#fff, 44px, 1.2);
    }
    .title {
      margin-top: 5px;
      @include cl-fs-linh(#fff, 16px, 1.2);
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    background-color: #272b2b;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    li {
      position: relative;
      flex: 1;
      //   width: 25%;
      height: 100%;
      opacity: 0.8;
      &:hover {
        a {
          transform: translateY(-5px);
        }
      }
      & > a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        transition: all 0.3s ease;
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 90px;
      }
      &::before {
        background-color: #617a7d;
      }
      &::after {
        right: -1px;
        background-color: #284448;
      }
      &:last-child::after,
      &:last-child::before {
        display: none;
      }
      .iconfont {
        padding-right: 10px;
        @include cl-fs-linh(#fff, 36px, 90px);
      }
      .title {
        @include cl-fs-linh(#fff, 16px, 90px);
      }
    }
  }
}

.snews {
  position: relative;
  display: flex;
  justify-content: center;
  .viewmore {
    position: absolute;
    bottom: 40px;
    left: 50px;
    border-color: #666666;
    color: #666666;
  }
  .list {
    padding: 40px 50px;
    width: 635px;
    box-sizing: border-box;
    flex-shrink: 0;
    li {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      &:hover,
      &.active {
        .cont {
          height: 66px;
        }
        .time {
          color: #4677b7;
        }
        .title {
          color: #4677b7;
          &::before {
            background-color: #4677b7;
          }
        }
      }
      .time {
        display: inline-block;
        width: 52px;
        @include cl-fs-linh(#666666, 16px, 1.2);
        span {
          font-size: 20px;
        }
      }
      .title {
        position: relative;
        margin-bottom: 6px;
        padding-left: 38px;
        width: 444px;
        @include ellipsis();
        @include cl-fs-linh(#666666, 16px, 1.5);
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 15px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #666;
          transform: translateY(-50%);
        }
      }
      .cont {
        height: 0;
        margin-bottom: 6px;
        padding-left: 90px;
        text-indent: 2em;
        @include cl-fs-linh(#a8a9a9, 14px, 1.6);
        overflow: hidden;
        transition: all 0.3s ease;
      }
    }
  }
  .img-list {
    flex-grow: 1;
    overflow: hidden;
    li.active {
      .imgstyle {
        height: 360px;
        opacity: 1;
      }
    }
    .imgstyle {
      height: 0;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
