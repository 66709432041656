.fadeInUp-20 {
  animation-name: fadeInUp-20;
  transition: opacity 0.5s ease-out 0.4s, transform 1s ease-out 0.4s;
}

@keyframes fadeInUp-20 {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp-40 {
  animation-name: fadeInUp-40;
  transition: opacity 0.5s ease-out 0.4s, transform 1s ease-out 0.4s;
}

@keyframes fadeInUp-40 {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp-little {
  animation-name: fadeInUp-little;
  transition: opacity 0.5s ease-out 0.4s, transform 1s ease-out 0.4s;
}

@keyframes fadeInUp-little {
  0% {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeIn-little {
  animation-name: fadeIn-little;
  transition: opacity 0.5s ease-out 0.4s;
}

@keyframes fadeIn-little {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeInDown-40 {
  animation-name: fadeInDown-40;
  transition: opacity 0.5s ease-out 0.4s, transform 1s ease-out 0.4s;
}

@keyframes fadeInDown-40 {
  0% {
    opacity: 0;
    transform: translate3d(0, -40px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft-40 {
  animation-name: fadeInLeft-40;
  transition: opacity 0.5s ease-out 0.4s, transform 1s ease-out 0.4s;
}

@keyframes fadeInLeft-40 {
  0% {
    opacity: 0;
    transform: translate3d(-40px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.slideInUp-30 {
  animation-name: slideInUp-30;
}

@keyframes slideInUp-30 {
  from {
    transform: translate3d(0, 30px, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
