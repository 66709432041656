@import '~@/platform/desktop/styles/set.scss';
.introduction {
  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    @include cl-fs-linh(#727171, 16px);
    font-weight: bold;
    text-align: left;
  }
  &.img-detail {
    .common-imglist {
      li {
        &:nth-child(even) {
          margin-right: 0;
        }
        width: calc((100% - 40px) / 2);
        .imgstyle {
          height: 320px;
        }
      }
    }
  }
}
