@font-face {
  font-family: 'socialshare';
  src: url('iconfont.08b47518.eot');
  /* IE9*/
  src: url('iconfont.08b47518.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('iconfont.4a21a814.woff') format('woff'),
    /* chrome, firefox */ url('iconfont.e6a1ee00.ttf') format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url('iconfont.8fa0a868.svg#iconfont') format('svg');
  /* iOS 4.1- */
}
.social-share {
  font-family: 'socialshare' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  /* display: none;
  position: absolute;
  right:75px;
  top:0;
  padding-top:26px;
  padding-bottom:26px;
  width:200px;
  background:#fff;*/
}
.social-share.active {
  display: block;
}
.social-share * {
  font-family: 'socialshare' !important;
}
.social-share .icon-tencent:before {
  content: '\f07a';
}
.social-share .icon-qq:before {
  content: '\f11a';
}
.social-share .icon-weibo:before {
  content: '\f12a';
}
.social-share .icon-wechat:before {
  content: '\f09a';
}
.social-share .icon-douban:before {
  content: '\f10a';
}
.social-share .icon-heart:before {
  content: '\f20a';
}
.social-share .icon-like:before {
  content: '\f00a';
}
.social-share .icon-qzone:before {
  content: '\f08a';
}
.social-share .icon-linkedin:before {
  content: '\f01a';
}
.social-share .icon-diandian:before {
  content: '\f05a';
}
.social-share .icon-facebook:before {
  content: '\f03a';
}
.social-share .icon-google:before {
  content: '\f04a';
}
.social-share .icon-twitter:before {
  content: '\f06a';
}
.social-share > a {
  position: relative;
  text-decoration: none;
  margin: 4px;
  display: inline-block;
  outline: none;
  color: red;
}

.social-share .social-share-icon {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 20px;
  border-radius: 50%;
  line-height: 32px;
  /* border: 1px solid #666; */
  color: #666;
  text-align: center;
  vertical-align: middle;
  transition: background 0.6s ease-out 0s;
}
.detail-share .social-share .social-share-icon:hover {
  background: #666;
  color: #fff;
}
.detail-share .social-share > .icon-weibo {
  color: #ff763b;
  border-color: #ff763b;
}
.detail-share .social-share .icon-weibo:hover {
  background: #ff763b;
}
.detail-share .social-share > .icon-tencent {
  color: #56b6e7;
  border-color: #56b6e7;
}
.detail-share .social-share .icon-tencent:hover {
  background: #56b6e7;
}
.detail-share .social-share > .icon-qq {
  color: #56b6e7;
  border-color: #56b6e7;
}
.detail-share .social-share .icon-qq:hover {
  background: #56b6e7;
}
.detail-share .social-share > .icon-qzone {
  color: #fdbe3d;
  border-color: #fdbe3d;
}
.detail-share .social-share .icon-qzone:hover {
  background: #fdbe3d;
}
.detail-share .social-share .icon-douban {
  color: #33b045;
  border-color: #33b045;
}
.detail-share .social-share .icon-douban:hover {
  background: #33b045;
}
.detail-share .social-share > .icon-linkedin {
  color: #0077b5;
  border-color: #0077b5;
}
.detail-share .social-share .icon-linkedin:hover {
  background: #0077b5;
}
.detail-share .social-share > .icon-facebook {
  color: #44619d;
  border-color: #44619d;
}
.detail-share .social-share .icon-facebook:hover {
  background: #44619d;
}
.detail-share .social-share > .icon-google {
  color: #db4437;
  border-color: #db4437;
}
.detail-share .social-share .icon-google:hover {
  background: #db4437;
}
.detail-share .social-share > .icon-twitter {
  color: #55acee;
  border-color: #55acee;
}
.detail-share .social-share .icon-twitter:hover {
  background: #55acee;
}
.detail-share .social-share > .icon-diandian {
  color: #307dca;
  border-color: #307dca;
}
.detail-share .social-share .icon-diandian:hover {
  background: #307dca;
}
.detail-share .social-share > .icon-wechat {
  position: relative;
  color: #7bc549;
  border-color: #7bc549;
}
.detail-share .social-share .icon-wechat:hover {
  background: #7bc549;
}
.detail-share .social-share .icon-wechat .wechat-qrcode {
  display: none;
  border: 1px solid #eee;
  position: absolute;
  z-index: 9;
  top: -205px;
  left: -84px;
  width: 200px;
  height: 192px;
  color: #666;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 10px #aaa;
  transition: all 200ms;
  -webkit-tansition: all 350ms;
  -moz-transition: all 350ms;
}
.detail-share .social-share .icon-wechat .wechat-qrcode.bottom {
  top: 40px;
  left: -84px;
}
.detail-share .social-share .icon-wechat .wechat-qrcode.bottom:after {
  display: none;
}
.detail-share .social-share .icon-wechat .wechat-qrcode h4 {
  font-weight: normal;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  background-color: #f3f3f3;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  color: #777;
}
.detail-share .social-share .icon-wechat .wechat-qrcode .qrcode {
  width: 105px;
  margin: 10px auto;
}
.detail-share .social-share .icon-wechat .wechat-qrcode .qrcode table {
  margin: 0 !important;
}
.detail-share .social-share .icon-wechat .wechat-qrcode .help p {
  font-weight: normal;
  line-height: 16px;
  padding: 0;
  margin: 0;
}
.detail-share .social-share .icon-wechat .wechat-qrcode:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -6px;
  bottom: -13px;
  width: 0;
  height: 0;
  border-width: 8px 6px 6px 6px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.detail-share .social-share .icon-wechat:hover .wechat-qrcode {
  display: block;
}
