@import '~@/platform/desktop/styles/set.scss';
.education-top,
.v-box {
  .viewmore {
    position: absolute;
    top: 40px;
    right: 48px;
    @include cl-fs-linh(#355a8b, 14px, 1.2);
  }
  .title-list {
    li {
      margin-bottom: 18px;
      &:hover {
        .time,
        .title {
          color: $color-main;
        }
      }
    }
    .time {
      margin-bottom: 3px;
      @include cl-fs-linh(#aeaeae, 14px, 1.2);
      transition: all 0.3s ease;
    }
    .title {
      @include ellipsis();
      @include cl-fs-linh(#666666, 16px, 1.2);
      transition: all 0.3s ease;
    }
  }
}
.education-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  > div {
    width: 529px;
  }
  .box {
    position: relative;
    padding: 70px 48px 0;
    height: 377px;
    box-sizing: border-box;
    @include background();
    background-image: url('#{$g-imgsrc}/education/bg.jpg');
  }

  .video-list {
    display: flex;
    justify-content: space-between;
    > li {
      padding: 2px 2px 0;
      width: 196px;
      box-shadow: 0 0 4px 2px #ccc;
      background-color: #fff;
    }
    .imgstyle {
      height: 218px;
      padding: 5px;
    }
    .title {
      margin-top: 3px;
      text-align: center;
      @include ellipsis();
      @include cl-fs-linh(#666666, 14px, 32px);
    }
  }
}
.v-box {
  position: relative;
  padding: 45px 44px 0;
  height: 400px;
  box-sizing: border-box;
  @include background();
  background-image: url('#{$g-imgsrc}/education/volunteer.jpg');
  .title-list {
    max-width: 440px;
  }
  .viewmore {
    top: 342px;
    right: auto;
    left: 48px;
  }
}
