$font-family: 'SimSun', 'Microsoft YaHei', 'PingFang SC', 'Heiti SC', sans-serif;
$color-main: #355a8b; //主色
$color-secondary: #c7a76d; //点缀色
$color-default: #a7a7a7; //点缀色
$text-color: #080808;
$text-color-light: #efefef;
$text-color-dark: #6d6d6d;

$font-family-main: 'mainfont';
$font-family-bold: 'timeBoldfont';
$font-family-roman: 'timeRomanfont';

%mainfont {
  font-family: $font-family-main;
  -webkit-font-smoothing: antialiased;
  -mox-osx-font-smoothing: grayscale;
}

%boldfont {
  font-family: $font-family-bold;
  -webkit-font-smoothing: antialiased;
  -mox-osx-font-smoothing: grayscale;
}
%romanfont {
  font-family: $font-family-roman;
  -webkit-font-smoothing: antialiased;
  -mox-osx-font-smoothing: grayscale;
}

// 清除浮动
%clearfix {
  *zoom: 1;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// 多行省略
@mixin ellipsis($n: 1) {
  overflow: hidden;
  @if ($n > 1) {
    display: -webkit-box;
    white-space: normal !important;
    text-overflow: ellipsis;
    // /*! autoprefixer: off */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    // /*! autoprefixer: on */
    -webkit-line-clamp: $n;
  } @else {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

%border-box {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

%vertival {
  writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-lr;
}

//通用
//模块居中
@mixin centerbox($w, $h) {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -$h/2;
  margin-left: -$w/2;
  width: $w;
  height: $h;
}

//background repeat position size
@mixin bgrepeatpositionsize($size: contain) {
  background: {
    repeat: no-repeat;
    position: center;
    size: $size;
  }
}

//color font-size-line-height
@mixin cl-fs-linh($color: #444, $fontsize: 14px, $linh: 1.5) {
  font-size: $fontsize;
  color: $color;
  line-height: $linh;
}

//简单动画
@mixin transition(
  $property: all,
  $time: 0.3s,
  $cubic-bezier: cubic-bezier(0.165, 0.84, 0.44, 1)
) {
  transition: $property $time $cubic-bezier;
}

@mixin background($size: contain, $repeat: no-repeat, $position: center) {
  background: $repeat $position / $size;
}

// 三角形
@mixin triangle($direction: top, $size: 5px, $borderColor: #ddd) {
  content: '';
  height: 0;
  width: 0;
  border-width: $size;
  border-style: solid;
  border-color: transparent;
  @if $direction == top {
    border-bottom-style: dashed;
    border-bottom-color: $borderColor;
  } @else if $direction == right {
    border-left-style: dashed;
    border-left-color: $borderColor;
  } @else if $direction == bottom {
    border-top-style: dashed;
    border-top-color: $borderColor;
  } @else if $direction == left {
    border-right-style: dashed;
    border-right-color: $borderColor;
  }
}

@mixin imglist($n: 4, $imgh: 200px, $mt: 40px, $mr: 30px, $align: left) {
  margin-top: -$mt / 2;
  li {
    display: inline-block;
    width: calc((100% - (#{$n} - 1) *#{$mr}) / #{$n});
    margin-top: $mt;
    margin-right: $mr;
    vertical-align: top;
    &:nth-child(#{$n}n) {
      margin-right: 0;
    }
    .imgstyle {
      height: $imgh;
    }
    .cont {
      padding: 12px 20px 0;
      text-align: $align;
      h3 {
        @include cl-fs-linh(#727171, 16px);
        @include ellipsis();
      }
      p {
        @include cl-fs-linh(#727171, 14px);
        @include ellipsis();
      }
      h3 + p {
        padding-top: 3px;
      }
    }
  }
}

/*

    可伸缩的框

 */

@mixin border-style(
  $name,
  $location: x,
  $size: 0,
  $pad: 40px,
  $isactive: false
) {
  .#{$name},
  .#{$name}:before,
  .#{$name}:after {
    background-image: url('~@/assets/images/#{$name}.png');
    opacity: 1;
  }

  .#{$name} {
    position: relative;
    @if $location==x {
      padding: $pad;
      min-width: 100px;
      background-position-y: -$size;
    } @else {
      padding: $pad;
      min-height: 200px;
      background-position-x: -$size;
    }
    box-sizing: border-box;
    &:before,
    &:after {
      content: '';
      position: absolute;
      @if $location==x {
        top: 0;
        width: $pad;
        height: 100%;
      } @else {
        left: 0;
        width: 100%;
        height: $pad;
      }
      background-color: $inner-cont-bgcolor;
      background-repeat: no-repeat;
      z-index: 0;
    }
    &:before {
      @if $location==x {
        left: 0;
        background-position-y: 0;
      } @else {
        top: 0;
        background-position-x: 0;
      }
    }
    &:after {
      @if $location==x {
        right: 0;
        background-position-y: -$size * 2;
      } @else {
        bottom: 0;
        background-position-x: -$size * 2;
      }
    }
    @if $isactive {
      .#{$name}.active,
      .#{$name}.active:before,
      .#{$name}.active:after {
        background-image: url('~@/assets/images/#{$name}-active.png');
      }
    }
  }
}
