@import '~@/platform/desktop/styles/set.scss';
.textlist {
  li {
    line-height: 70px;
    border-bottom: 1px solid #dfdad0;
    a {
      position: relative;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #898989;
      transition: color 0.3s;
      &:hover {
        color: $color-main;
        &:before {
          border: 2px solid $color-main;
        }
      }
    }
    h3 {
      width: 70%;
      padding-left: 40px;
      padding-right: 80px;
      font-size: 16px;
      @include ellipsis();
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 9px;
        height: 9px;
        margin-top: -7px;
        border: 2px solid #898989;
        border-radius: 50%;
      }
    }
    /*   p {
      width: 200px;
    } */
  }
}

/* .otherlan {
  .textlist li {
    h3 {
      width: 70%;
    }
    p {
      display: none;
    }
  }
} */
