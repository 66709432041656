@import '~@/platform/desktop/styles/set.scss';
.img-cont-list {
  li {
    height: 130px;
    margin-top: 20px;
    padding: 20px 50px 20px 45px;
    background-color: #f7f7f7;
    transition: all 0.5s;
    a {
      display: flex;
      height: 100%;
      justify-content: space-between;
    }
    .date {
      position: relative;
      padding-right: 50px;
      p {
        line-height: 1;
        &:first-child {
          margin-top: 30px;
          margin-bottom: 20px;
          font-size: 30px;
          color: #5f5f5f;
          font-weight: bold;
        }
        &:last-child {
          font-size: 20px;
          color: #b0b0b0;
        }
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: -5%;
        width: 1px;
        height: 110%;
        background-color: #dcdcdc;
      }
    }
    .cont {
      margin-left: 50px;
      margin-right: 85px;
      overflow: hidden;
      width: 100%;
      h3 {
        margin: 20px 0 13px;
        font-size: 20px;
        color: #323232;
        transition: color 0.5s ease-out;
        @include ellipsis();
        span {
          margin-left: 5px;
        }
      }
      p {
        @include cl-fs-linh(#7f7f7f, 14px, 26px);
        text-align: justify;
        text-indent: 2em;
      }
    }
    .imgstyle {
      flex-shrink: 0;
      width: 200px;
      height: 130px;
    }
    &:hover {
      box-shadow: 0px 2px 9px 4px rgba(62, 62, 62, 0.1);
      transform: translateY(-3px);
    }
  }
}
