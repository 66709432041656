@import '~@/styles/set.scss';

$g-imgsrc: '~@/assets/images/desktop'; //图片路径
$font-family: 'Microsoft YaHei', 'PingFang SC', 'SimSun', 'Heiti SC', sans-serif;
$font-size-base: 14px; //默认字号
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$line-height-base: 1.5;
$border-radius-base: 4px;
$border-radius-sm: 2px;

// 适配
$screen-lg-min: 1750px;
$screen-md-min: 1450px;
$screen-lg: 'max-width:' + $screen-lg-min;
$screen-md: 'max-width:' + $screen-md-min;

//首页
$index-allcenter-width-lg: 1680px;
$index-allcenter-width-md: 1420px;
$index-allcenter-width-sm: 1250px;

$inner-allcenter-out: 1250px;
$inner-allcenter-inner: 1100px;
$detail-width: 864px;

//内页
$inner-body-bgcolor: #f7f8f8;
$inner-cont-bgcolor: #fff;
