@import '~@/styles/set.scss';
.mapbox-pc {
  position: relative;
  width: 396px;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  .map {
    height: 100%;
    background: #ccc;
    @include transition();
  }
  .BMap_pop {
    & > div {
      & div {
        border-color: #fff !important;
        border-bottom: 0 !important;
      }
    }
    .BMap_top {
      border-color: #fff;
    }
    .BMap_center {
      border-color: #fff;
    }
    .BMap_bottom {
      border: 0;
    }
    .BMap_bubble_content {
      & > h3 {
        margin: -5px 0 7px;
        @include cl-fs-linh(#171717, 15px);
      }
      & > p {
        margin-bottom: 5px;
        @include cl-fs-linh(#6b6b6b, 14px);
      }
    }
  }
  .BMap_tip {
    margin-top: 12px;
    & > h3 {
      margin: 5px 0;
      @include cl-fs-linh(#171717);
    }
    & > p {
      margin-bottom: 5px;
      @include cl-fs-linh(#6b6b6b, 14px);
    }
  }
}
