@import '~@/platform/desktop/styles/set.scss';

// 推荐
.recommendtemp {
  $h: 570px;
  position: relative;
  height: $h;
  margin-top: 45px;
  line-height: 0;
  .item {
    height: $h;
    a {
      display: block;
      height: $h;
    }
    img {
      opacity: 0.8;
      width: 100%;
      height: $h;
      object-fit: cover;
    }
  }

  .slick-active {
    img {
      opacity: 1;
      @include transition(all, 5s);
      transform: scale(1.1);
    }
  }
  .slick-slider {
    margin: 0 -50px;
    .slick-dots {
      position: absolute;
      width: 100%;
      left: 0;
      height: 45px;
      bottom: 0;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-size: 0;
      background: linear-gradient(transparent, rgba(20, 20, 20, 0.3));
      li {
        width: 35px;
        height: 5px;
        margin: 0 7px;
        button {
          width: 100%;
          height: 100%;
          background: rgba(#fff, 0.3);
          border-radius: 5px;
          cursor: pointer;
        }
        &.slick-active {
          button {
            background: #fff;
          }
        }
      }
    }
  }
}

// 常设展览
.baseexhdetail {
  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    @include cl-fs-linh(#727171, 16px);
    font-weight: bold;
  }
}
.baseexh {
  margin: 0 -50px;
  li {
    @extend %clearfix;
    margin-bottom: 20px;
    padding: 40px 88px;
    background: no-repeat 100% / cover;
    a {
      display: inline-block;
      // justify-content: space-between;
      position: relative;
    }
    .imgstyle {
      flex-shrink: 0;
      width: 370px;
      height: 220px;
      border-radius: 5px;
    }
    .cont {
      width: 433px;
      margin-bottom: 135px;
      h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 16px;
        @include cl-fs-linh(#d7e8f5, 18px);
        border-bottom: 1px solid #d7e8f5;
      }
      .viewmore {
        padding: 6px 15px;
        @include cl-fs-linh(#fff, 14px, 1);
        border-radius: 4px;
        position: absolute;
        bottom: 38px;
        left: 0;
        background: rgba(40, 74, 97, 0.7);
      }
      p {
        margin-top: 30px;
        text-align: justify;
        @include cl-fs-linh(#d7e8f5, 14px, 1.8);
      }
    }
    &:last-child {
      .cont {
        h3 {
          @include cl-fs-linh(#414141, 18px);
          border-bottom: 1px solid #414141;
        }
        .viewmore {
          background: rgba(83, 83, 83, 0.7);
        }
        p {
          @include cl-fs-linh(#414141, 14px, 1.8);
        }
      }
    }
  }
}

.baseexhlist {
  $w: 368px;
  $imgw: 380px;
  li {
    margin-top: 30px;
    padding: 32px 40px;
    transition: all 0.5s;
    border: 5px solid #edeef0;
    &:nth-child(2n-1) {
      .lia {
        flex-direction: row-reverse;
        .cont {
          margin-left: 0;
          margin-right: 40px;
        }
      }
    }
    .lia {
      display: block;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .imgstyle {
        width: $imgw;
        height: 222px;
        //margin: 56px 60px;
        background: #000;
        border-radius: 10px;
        flex-shrink: 0;
      }
      .cont {
        width: 100%;
        height: 222px;
        overflow: hidden;
        position: relative;
        //padding: 31px 0;
        margin-left: 40px;

        a {
          background: #355a8e;
          border-radius: 5px;
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          color: #f7f4f1;
          padding: 2px 14px;
        }
        h5 {
          margin-bottom: 6px;
          @include cl-fs-linh(#365a8c, 20px);
          @include ellipsis;
        }
        .abstract {
          p {
            @include cl-fs-linh(#9fa0a0, 14px, 1.6);
          }
          .abstractp {
            border-top: 1px solid #e7ebee;
            color: #595757;
            margin-top: 10px;
            padding-top: 10px;
            position: relative;
            font-size: 14px;
            text-indent: 2em;
          }
        }
      }
    }

    &:hover {
      transform: translateY(-3px);
    }
  }
}

.detailshort {
  width: $detail-width;
  margin: 0 auto;
}

// 临展详情
.timesearch {
  text-align: center;
  ul {
    display: inline-block;
    li {
      display: inline-block;
      margin-right: 30px;
      cursor: pointer;
      div,
      a {
        color: #595757;
        font-size: 16px;
        &::before,
        &::after {
          display: inline-block;
          content: '';
          width: 13px;
          height: 16px;
          margin-top: 3px;
          vertical-align: text-top;
        }
        &::before {
          background-image: url('#{$g-imgsrc}/exhibition/left.png');
          margin-right: 10px;
        }
        &::after {
          background-image: url('#{$g-imgsrc}/exhibition/right.png');
          margin-left: 10px;
        }
        &.active {
          color: #355a8b;
          &::before {
            background-image: url('#{$g-imgsrc}/exhibition/left_active.png');
          }
          &::after {
            background-image: url('#{$g-imgsrc}/exhibition/right_active.png');
          }
        }
      }
    }
  }
}

.tempexhdetail {
  %border {
    border-bottom: 1px solid #c1d0db;
  }
  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    @include cl-fs-linh(#727171, 16px);
    font-weight: bold;
  }

  .topbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .imgstyle {
      flex-shrink: 0;
      width: 500px;
      height: 300px;
      margin-right: 35px;
    }
    .cont {
      flex-grow: 1;
      & > h3 {
        $margin: 30px;
        padding-bottom: $margin;
        margin-bottom: $margin;
        @include cl-fs-linh(#727171, 20px);
        @extend %border;
      }
      .title {
        margin-top: 0;
      }
      p {
        color: #336289;
        line-height: 2;
      }
    }
  }
  .maindetail {
    margin-top: 0;
    .cont {
      margin-top: 0;
      color: #727171;
    }
  }
  .maindetail + .title + .common-imglist {
    padding-bottom: 50px;
    @extend %border;
  }
}

.tempexhlist {
  .lite {
    $mr: 40px;
    box-sizing: border-box;
    width: calc((100% - #{$mr}) / 2);
    margin-top: 30px;
    margin-right: $mr;
    margin-bottom: 10px;
    transition: all 0.5s;
    display: inline-block;
    &:hover {
      transform: translateY(-3px);
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    .lia {
      display: block;
      width: 100%;
      .imgstyle {
        height: 320px;
      }
      .cont {
        background: #eff0f2;
        padding: 24px 32px;
        h3 {
          font-size: 16px;
          color: #365a8c;
          margin-bottom: 10px;
          @include ellipsis(1);
        }
        p {
          margin-bottom: 5px;
          font-size: 14px;
          color: #949494;
        }
      }
    }
  }
  .lisp {
    $mr: 40px;
    box-sizing: border-box;
    width: calc((100% - #{$mr}) / 2);
    margin-top: 40px;
    margin-right: $mr;
    transition: all 0.5s;
    display: inline-block;
    border: 1px solid #bdc0c6;
    border-radius: 25px;
    &:hover {
      transform: translateY(-3px);
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    .lia {
      display: block;
      width: 96%;
      margin: 10px;
      border: 1px solid #e1e2e5;
      border-radius: 20px;
      .imgstyle {
        padding: 28px 40px 10px;
        height: 250px;
        .img {
          border-radius: 5px;
        }
      }
      .cont {
        padding: 0 40px 24px;
        h3 {
          font-size: 16px;
          color: #365a8c;
          margin-bottom: 5px;
          @include ellipsis();
        }
        p {
          font-size: 14px;
          color: #aaaaaa;
        }
      }
    }
  }
}

.musintro {
  &.baseexhibition {
    .slick-track {
      height: 100%;
    }
    .slick-slider {
      display: flex;
      flex-direction: row-reverse;
      height: 400px;
      .item {
        height: 400px;
        background-color: #ccc;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .slick-dots {
      flex-shrink: 0;
      width: 411px;
      @include background();
      background-image: url('#{$g-imgsrc}/exhibition/baseexhibition-bg.png');
      .dotsL {
        margin: 36px 0;
        li {
          padding: 29px 0;
          text-align: center;
          transition: color 0.3s ease;
          cursor: pointer;
          h3 {
            padding: 0 50px;
            font-size: 24px;
            @include ellipsis();
            @include cl-fs-linh(#c5a479, 20px, 1.2);
          }
        }
        .slick-active {
          h3 {
            color: #ecc490;
          }
        }
      }
    }
    .slick-list {
      flex-grow: 1;
    }
  }
  &.specialexh {
    position: relative;
    height: 400px;
    background: url('#{$g-imgsrc}/exhibition/special-bg.png') no-repeat 50% /
      cover;
    padding-right: 45px;
    .special-img {
      float: left;
      width: 687px;
      height: 400px;
      li {
        width: 100%;
        .imgstyle {
          height: 0;
          opacity: 0;
          transition: all 0.3s ease;
        }
        &.active {
          height: 100%;
          .imgstyle {
            height: 100%;
            opacity: 1;
          }
        }
      }
    }
    .special-list {
      float: right;
      width: 330px;
      margin-top: 50px;
      li {
        border-bottom: 1px solid #d2d2d2;

        line-height: 28px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        text-indent: 1em;
        a {
          color: #666666;
        }
        &.active {
          color: #4677b7;

          a {
            color: #4677b7;
          }
        }
        h5 {
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &::before {
            position: absolute;
            content: '';
            width: 5px;
            height: 5px;
            background-color: #666;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 1px;
          }
          a {
            color: #666666;
            font-size: 18px;
          }
        }
        .time {
          font-size: 14px;
        }
      }
      .more {
        float: right;
        font-size: 14px;
        color: #88a6d0;
        border: 1px solid #88a6d0;
        padding: 3px 5px;
        border-radius: 5px;
      }
    }
  }
  &.tempexh {
    .img-banner {
      $h: 580px;
      position: relative;
      height: $h;
      line-height: 0;
      .item {
        height: $h;
        a {
          display: block;
          height: $h;
        }
        img {
          width: 100%;
          height: $h;
          object-fit: cover;
        }
      }

      .slick-slider {
        .slick-dots {
          position: absolute;
          width: 100%;
          left: 0;
          height: 100px;
          bottom: 0;
          display: flex !important;
          justify-content: center;
          align-items: center;
          font-size: 0;
          background: linear-gradient(transparent, rgba(20, 20, 20, 0.2));
          li {
            width: 8px;
            height: 8px;
            border-radius: 8px;
            border: 2px solid #fff;
            margin: 20px 5px 10px;
            button {
              width: 100%;
              height: 100%;
              background: rgba(#fff, 0.3);
              border-radius: 5px;
              cursor: pointer;
            }
            &.slick-active {
              width: 33px;
              button {
                background: #fff;
              }
            }
          }
        }
      }
      .slick-active {
        z-index: 1;
      }
    }
    .temp-list {
      color: #666;
      font-size: 14px;
      .title-list {
        padding: 0 45px;
        margin-top: 40px;
        height: 45px;
        line-height: 45px;
        background-color: #e5e5e5;
        font-size: 16px;
        li {
          display: inline-block;
          &:first-child {
            width: 500px;
            margin-right: 25px;
          }
          &:nth-child(2) {
            width: 240px;
            margin-right: 25px;
          }
        }
      }
      .content-list {
        li {
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #e9e9e9;
          p {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #666;
            &:first-child {
              position: relative;
              width: 500px;
              margin-right: 25px;
              margin-left: 45px;
              text-indent: 2em;
              &::before {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                border: 1px solid #666;
                border-radius: 50%;
              }
            }
            &:nth-child(2) {
              width: 240px;
              margin-right: 25px;
            }
            &:last-child {
              width: 230px;
            }
          }
        }
      }
    }
    .more-box {
      margin-top: 30px;
      margin-bottom: 50px;
      text-align: center;
      .more {
        font-size: 14px;
        color: #88a6d0;
        border: 1px solid #88a6d0;
        padding: 3px 5px;
        border-radius: 5px;
      }
    }
  }
}

.online {
  position: relative;
  height: 360px;
  background: url('#{$g-imgsrc}/exhibition/online-bg.jpg') no-repeat 50% / cover;
  .more {
    position: absolute;
    bottom: 109px;
    left: 252px;
    font-size: 14px;
    line-height: 24px;
    padding: 2px 7px;
    border-radius: 4px;
    color: #fff;
    background-color: #a18969;
  }
}
.main-title {
  .more {
    &.baseexh-more {
      color: #355a8b;
      line-height: 2.5;
    }
  }
}
