@import '~@/platform/desktop/styles/set.scss';

.journallist {
  @include imglist($n: 4, $imgh: 330px, $mt: 40px, $mr: 24px, $align: center);
  margin-left: 15px;
  margin-right: 15px;
  li {
    text-align: center;
    .imgstyle {
      padding: 7px 8px;
      border: 1px solid #ced2d5;
    }
    .shade {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: #fff;
      background-color: rgba(#436d90, 0.9);
      transition: all 0.5s;
      .info {
        margin-bottom: 45px;
        line-height: 26px;
        padding-left: 10px;
      }
      & > span {
        $w: 45px;
        width: $w;
        height: $w;
        line-height: $w;
        //@extend %vertival;
        color: #436d90;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    // &:hover {
    //   .shade {
    //     opacity: 1;
    //   }
    // }
  }
}
.journal {
  display: flex;
  height: 420px;
  margin-top: -30px;
  background: url('#{$g-imgsrc}/stydy/journal.png') no-repeat 50% / cover;
  padding-left: 105px;
  padding-top: 140px;
  box-sizing: border-box;
  .cont {
    width: 360px;
    p,
    a {
      color: #f4f0dd;
      font-size: 14px;
      line-height: 26px;
      text-indent: 2em;
    }
    p {
      margin-bottom: 40px;
    }
    .more {
      border: 1px solid #f4f0dd;
      padding: 1px 5px;
      border-radius: 2px;
    }
  }
}
.research-sec {
  display: flex;
  justify-content: flex-start;
  .first {
    margin-right: 40px;
  }
  .research-list {
    width: 529px;
    height: 377px;
    padding: 40px 50px 0;
    background: url('#{$g-imgsrc}/stydy/sec-bg.png') no-repeat 50% / cover;
    box-sizing: border-box;
    ul {
      margin-top: 30px;
      li {
        margin-bottom: 30px;
        .time {
          color: #aeaeae;
          font-size: 14px;
          line-height: 20px;
          transition: all 0.3s ease;
        }
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          color: #666666;
          transition: all 0.3s ease;
        }
        .top {
          .title {
            span {
              position: relative;
              &:after {
                position: absolute;
                content: '置顶';
                width: 33px;
                height: 18px;
                color: #fff;
                line-height: 18px;
                font-size: 12px;
                background-color: red;
                text-align: center;
                border-radius: 2px;
                top: 3px;
                right: -40px;
              }
            }
          }
        }
        &:hover {
          a {
            .time,
            .title {
              color: #355a8b;
            }
          }
        }
      }
    }
    .more {
      float: right;
      color: #355a8b;
      font-size: 14px;
    }
  }
}
