/*

    可伸缩的框

 */

@mixin border-style($name, $location: x, $size: 0, $pad: 40px) {
  .#{$name},
  .#{$name}:before,
  .#{$name}:after {
    background-image: url(~@/assets/images/desktop/common/#{$name}.png);
    opacity: 1;
  }

  .#{$name} {
    position: relative;
    @if $location==x {
      padding: $pad;
      min-width: 100px;
      background-position-y: -$size;
    } @else {
      padding: $pad;
      min-height: 200px;
      background-position-x: -$size;
    }
    box-sizing: border-box;
    &:before,
    &:after {
      content: '';
      position: absolute;
      @if $location==x {
        top: 0;
        width: $pad;
        height: 100%;
      } @else {
        left: 0;
        width: 100%;
        height: $pad;
      }
      background-color: $inner-cont-bgcolor;
      background-repeat: no-repeat;
      z-index: 0;
    }
    &:before {
      @if $location==x {
        left: 0;
        background-position-y: 0;
      } @else {
        top: 0;
        background-position-x: 0;
      }
    }
    &:after {
      @if $location==x {
        right: 0;
        background-position-y: -$size * 2;
      } @else {
        bottom: 0;
        background-position-x: -$size * 2;
      }
    }
  }
}

// @include border-style(border-longbar, y, 1100px, 40px);

.border1100 {
  padding: 30px 0;
  min-height: 700px;
}
/* .beauty-border,
.beauty-border:before,
.beauty-border:after {
  background-image: url(~@/assets/images/desktop/common/border-longbar.png);
  opacity: 1;
}
.beauty-border {
  position: relative;
  padding: 40px 40px 40px;
  min-height: 500px;
  background-position-x: -1100px;
  box-sizing: border-box;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: $inner-cont-bgcolor;
    background-repeat: no-repeat;
  }
  &:before {
    top: 0;
    background-position-x: 0;
  }
  &:after {
    bottom: 0;
    background-position-x: -2200px;
  }
}
 */
