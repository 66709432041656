.bigimg-cont-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    display: inline-block;
    width: 262px;
    height: 334px;
    margin-bottom: 45px;
    background-color: #efefef;
    .imgstyle {
      width: 100%;
      height: 261px;
    }
    p {
      color: #727171;
      font-size: 14px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.list-name {
        font-size: 16px;
        margin-top: 12px;
      }
      &.list-year {
        margin-top: 2px;
      }
    }
  }
}
