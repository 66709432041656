@import '~@/platform/desktop/styles/set.scss';

.wechat,
.weibo {
  position: relative;
  cursor: pointer;
  .icon {
    width: 24px;
    height: 24px;
    display: block;
    background: no-repeat center / contain;
  }
  .imgbox {
    $w: 120px;
    display: none;
    position: absolute;
    top: -$w - 25;
    left: 50%;
    width: $w;
    height: $w + 2;
    padding: 5px;
    transform: translateX(-50%);
    border: 1px solid #d2d2d2;
    background-color: #fff;
    img {
      width: 100%;
    }
  }
  &:hover .imgbox {
    display: block;
  }
}
// .wechat .icon {
//   background-image: url('#{$g-imgsrc}/index/icon-weixin.png');
// }
// .weibo .icon {
//   background-image: url('#{$g-imgsrc}/index/icon-weibo.png');
// }
