@import '~@/platform/desktop/styles/set.scss';

.innercollection {
  margin-bottom: 110px;
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    li {
      position: relative;
      left: 0;
      width: 220px;
      height: 430px;
      background-size: auto 104%;
      transform: none;
      @extend %vertival;
      box-reflect: below 0 -webkit-linear-gradient(transparent 60%, rgba(0, 0, 0, 0.2));
      -webkit-box-reflect: below 0 -webkit-linear-gradient(transparent 60%, rgba(0, 0, 0, 0.2));
    }
    .firstli {
      display: none;
    }
    .sixthli {
      display: block;
    }
  }
}

.collectiondetail {
  %border {
    border-bottom: 1px solid #c1d0db;
  }
  .topbox {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    #photoviewer {
      position: absolute;
      position: absolute;
      left: 325px;
      top: 15px;
      width: 63px;
      img {
        display: none;
        &:first-child {
          display: block;
        }
      }
    }
    .imgstyle {
      flex-shrink: 0;
      //   width: 340px;
      //   height: 383px;
      width: 400px;
      height: 450px;
      margin-right: 60px;
      .cover {
        object-fit: cover !important;
      }
    }
    .cont {
      color: #727171;
      flex-grow: 1;
      & > h3 {
        @include cl-fs-linh(#727171, 20px);
      }
      .about {
        @extend %border;
        margin-bottom: 14px;
      }
      .viewed {
        display: inline-block;
        $margin: 5px;
        padding-top: $margin;
        padding-bottom: $margin;
        margin-bottom: 5px;
        margin-right: 30px;
        i {
          display: inline-block;
          width: 24px;
          height: 20px;
          vertical-align: middle;
          background-image: url('#{$g-imgsrc}/common/status.png');
          background-position-x: 0;
        }
        span {
          margin-left: 10px;
        }
      }
      .detail-share {
        display: inline-block;
        $margin: 5px;
        padding-top: $margin;
        padding-bottom: $margin;
        margin-bottom: 5px;
        .social-share {
          display: inline-block;
          float: none;
          > a {
            margin: 4px 5px;
          }
        }
        > span {
          width: auto;
          color: #727171;
          float: none;
          margin-left: 10px;
          vertical-align: middle;
        }
        .share {
          display: inline-block;
          width: 24px;
          height: 20px;
          vertical-align: middle;
          background-image: url('#{$g-imgsrc}/common/status.png');
          background-position-x: -72px;
        }
      }
      .intro {
        // @extend %border;
        // padding-bottom: 14px;
        p {
          font-size: 16px;
        }
      }
      .abstract {
        margin-top: 10px;
        height: 120px;
        padding-right: 15px;
        text-indent: 2em;
        text-align: justify;
        overflow: auto;
        p {
          line-height: 1.75;
        }
      }
      p {
        line-height: 1.7;
      }
    }
  }
}
.col-recommand-image {
  width: 1100px;
  height: 360px;
  position: relative;
  z-index: 2;
  .imgborder {
    height: 100%;
    background: url('#{$g-imgsrc}/collection/treasure-bg.png') no-repeat 50% /
      cover;
    color: #e7eae6;
    > div {
      float: right;
      width: 365px;
      height: 240px;
      margin-top: 70px;
      margin-right: 130px;
      h3 {
        font-size: 28px;
        font-family: fangzheng;
      }
      p {
        font-size: 14px;
        text-indent: 2em;
        margin: 10px 0 25px;
        line-height: 26px;
      }
      .more {
        float: right;
        color: #e7eae6;
        border: 1px solid #e7eae6;
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
  }
}
.col-sort {
  li {
    display: inline-block;
    width: 236px;
    height: 324px;
    position: relative;
    margin-right: 50px;
    background: url(#{$g-imgsrc}/collection/collistbg.png) no-repeat center;
    margin-top: 30px;
    transition: all 0.3s ease;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:nth-child(7) {
      p {
        line-height: 22px;
      }
    }
    p {
      font-size: 24px;
      position: absolute;
      left: 14px;
      top: 16px;
      color: #8f6e43;
      width: 28px;
      transform: scale(0.8);
      transition: all 0.6s ease 0s;
      z-index: 10;
      line-height: 26px;
      font-family: fangzheng;
    }
    i {
      position: absolute;
      transform: scale(0.9);
      transition: all 0.6s ease 0s;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
    }
    &:hover {
      p {
        transform: scale(1) translate(89px, 12px);
      }
      i {
        transform: translateY(60px);
      }
    }
  }
}

.searchbar {
  height: 40px;
  line-height: 38px;
  margin: 30px 175px;
  &.colsearch {
    margin: 30px 265px;
  }
  form {
    > ul {
      > li {
        float: left;
        margin-right: 18px;
        font-size: 14px;
      }
    }
  }
  .border {
    border: 1px solid #d2cfcf;
    position: relative;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }
  .inputType {
    input {
      width: 265px;
      height: 28px;
      line-height: 28px;
      padding: 5px 14px;
      color: #878787;
      font-size: 14px;
      box-sizing: border-box;
    }
  }
  .selectType {
    position: relative;
    width: 162px;
    .choseVal {
      display: block;
      padding: 0 45px 0 20px;
      box-sizing: border-box;
      cursor: pointer;
      color: #878787;
      &input {
        display: none;
      }
    }
    input {
      &.choseVal {
        display: none;
      }
    }
    .toggleIcon {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 30px;
      background: url(#{$g-imgsrc}/collection/down.png) no-repeat center;
      transition: all 0.3s ease;
    }
    ul {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      background: #f8f8f8;
      box-shadow: 1px 1px 12px #bfbebe;
      z-index: 100;
      padding-top: 5px;
      transition: all 0.5s ease;
      display: none;
      max-height: 185px;
      overflow: auto;
      li {
        border-bottom: 1px solid #eaeaea;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #333;
        cursor: pointer;
        &.active {
          background: rgba(53, 90, 139, 0.2);
        }
      }
    }
    &.active {
      ul {
        display: block;
      }
    }
  }
  .searchbtn {
    width: 100px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    background-color: #355a8b;
    cursor: pointer;
    float: left;
    transition: all 0.3s ease;
    font-size: 14px;
  }
}

#search_list {
  .common-imglist {
    margin-top: -90px;
  }
}

.colimg-listbox {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 640px;
  height: 120px;
  .slide-content {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .slick-slider {
    .slick-list {
      height: 120px;
    }
    button {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
      width: 25px;
      color: transparent;
      &.slick-prev {
        left: -50px;
        background: url('#{$g-imgsrc}/common/event-pbt.png') no-repeat
          center/contain;
      }
      &.slick-next {
        right: -25px;
        background: url('#{$g-imgsrc}/common/event-nbt.png') no-repeat
          center/contain;
      }
    }
  }
  .owl-item {
    height: 120px;
    float: left;
    text-align: center;
    outline: none !important;
    .imgstyle {
      display: block;
      width: 132px;
      height: 120px;
      padding: 5px;
      background: #d5d5d5;
      cursor: pointer;
      box-sizing: border-box;
      &.active {
        border: 1px solid #355a8b;
      }
      img {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
