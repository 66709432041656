@import '~@/platform/desktop/styles/set.scss';
.forum-list-cont {
  .textlist {
    margin-top: 20px;
  }
  .list-box {
    .manuscript-box {
      margin-top: 44px;
    }
    .common-imglist {
      margin-top: 0;
      li {
        position: relative;
        margin-right: 34px;
        width: calc((100% - 102px) / 4);
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:hover {
          .cont {
            bottom: 0;
          }
        }
        .imgstyle {
          height: 261px;
        }
        .cont {
          position: absolute;
          bottom: -50px;
          width: 100%;
          box-sizing: border-box;
          background-color: rgba(21, 12, 9, 0.6);
          transition: all 0.3s ease;
          h3 {
            color: #c9caca;
            font-size: 14px;
          }
        }
      }
    }
  }
  .tablist {
    margin-top: 13px;
    text-align: right;
    border-bottom: 1px solid #c9caca;
    li {
      display: inline-block;
      margin: 0 12px;
      padding-bottom: 10px;
      color: #9fa0a0;
      cursor: pointer;
      &.active {
        color: #b29477;
      }
    }
  }
}

.jo-list {
  .jo-list-box {
    margin-top: 50px;
  }
}
