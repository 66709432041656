@import url(//at.alicdn.com/t/font_1458572_rk0zrhmyqt.css);
@import 'set.scss';

* {
  max-height: 999999px;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body {
  position: relative;
  -webkit-text-size-adjust: none; /*iphone手机文字显示兼容 Font Boosting*/
}

fieldset,
img {
  border: 0;
}
img {
  display: inline-block;
}
a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

input,
select,
textarea,
button {
  outline: none;
  border: none;
  background: none;
  font-size: 100%;
  font-family: Microsoft YaHei;
}

select,
input {
  vertical-align: middle;
}
textarea {
  overflow: auto;
  resize: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

sup,
sub {
  font-size: 100%;
}

i {
  font-style: normal;
}

label,
summary {
  cursor: default;
}

a,
button {
  cursor: pointer;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
}

input:-webkit-autofill {
  animation: autofill-fix 1s infinite;
}

@keyframes autofill-fix {
  from {
    background-color: transparent;
  }
  to {
    background: transparent;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vertival {
  writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-lr;
}
/*

    图片展示

*/
.imgstyle {
  position: relative;
  overflow: hidden;
  font-size: 0;
  &.use-img {
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: 100%;
      background: #d5d5d5 no-repeat center / cover;
      object-fit: cover;
    }
  }
  .img {
    display: block;
    height: 100%;
    background: #d5d5d5 url(~@/assets/images/default.jpg) no-repeat center /
      cover;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  //居中显示
  &.imgfull .img {
    background-size: contain;
  }
}
//图片动画
.imgscale:hover .img {
  transform: scale(1.05, 1.05);
}
//图片弧度
.imgradius {
  &.use-img {
    img {
      border-radius: 5px;
    }
  }
  .img {
    border-radius: 5px;
  }
}

//图片比例
.aspectration {
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
  }
  &[data-ratio='2:1']::after {
    padding-top: 50%;
  }
  &[data-ratio='16:9']::after {
    padding-top: 56.25%;
  }
  &[data-ratio='16:10']::after {
    padding-top: 62.5%;
  }
  &[data-ratio='1.448:1']::after {
    padding-top: 69.1%;
  }
  &[data-ratio='1:1']::after {
    padding-top: 100%;
  }
  &[data-ratio='1:1.414']::after {
    padding-top: 141.4%;
  }
  &[data-ratio='1:1.35']::after {
    padding-top: 135%;
  }
  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
/*

    仿Angular

 */
.hide {
  display: none;
}
.tc {
  text-align: center !important;
}
.tr {
  text-align: right !important;
}
.tl {
  text-align: left !important;
}
.fr {
  float: right !important;
}
.fl {
  float: left !important;
}
.margin-y {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-x {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}

.padding-y {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.padding-x {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}

/*

    字体文件

 */

// 方正宋刻本秀楷简体
@font-face {
  $font: 'fangzheng';
  font-family: $font;
  src: url('~@/assets/fonts/#{$font}/font/#{$font}.eot');
  src: url('~@/assets/fonts/#{$font}/font/#{$font}.eot?#font-spider')
      format('embedded-opentype'),
    url('~@/assets/fonts/#{$font}/font/#{$font}.woff') format('woff'),
    url('~@/assets/fonts/#{$font}/font/#{$font}.ttf') format('truetype'),
    url('~@/assets/fonts/#{$font}/font/#{$font}.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -mox-osx-font-smoothing: grayscale;
}

// Book Antiqua
@font-face {
  $font: 'num';
  font-family: $font;
  src: url('~@/assets/fonts/#{$font}/font/#{$font}.eot');
  src: url('~@/assets/fonts/#{$font}/font/#{$font}.eot?#font-spider')
      format('embedded-opentype'),
    url('~@/assets/fonts/#{$font}/font/#{$font}.woff') format('woff'),
    url('~@/assets/fonts/#{$font}/font/#{$font}.ttf') format('truetype'),
    url('~@/assets/fonts/#{$font}/font/#{$font}.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -mox-osx-font-smoothing: grayscale;
}
