.goback {
    /* float: right;
    cursor: pointer;
    height: 24px;
    margin-top: 2px;
    line-height: 24px;
    font-size: 14px;
    background: #d0bc9c;
    padding: 0 18px 0 31px;
    border-radius: 3px;
    color: #fff !important;
    letter-spacing: 1px;
    text-indent: 1px; */
    &.disable {
        pointer-events: none;
        opacity: 0.5;
    }
    &:before {
        // content: '';
        position: absolute;
        top: 0;
        left: 11px;
        width: 15px;
        height: 100%;
        // background: url(~@/assets/images/common/goback.png) no-repeat center;
    }
}
