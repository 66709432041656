@import '~@/platform/desktop/styles/set.scss';
//面包屑
.breadcrumb {
  margin-bottom: 35px;
  line-height: 1;
  font-size: 0;
  &.mobile {
    $w: 0.35rem;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - #{$w}* 2);
    margin-bottom: 0;
    padding: 0 0.35rem;
    background: #5594b5;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    z-index: 1;
    li {
      padding: 0.19rem 0;
      color: #fff;
      &:last-child a {
        color: #fff;
      }
      i {
        margin-top: 0;
        font-size: 0.26rem;
      }
      a {
        font-size: 0.28rem;
        color: #fff;
        padding: 0 0.1rem;
      }
      &.goback {
        margin-right: 0;
        text-align: right;
        font-size: 0.28rem;
        i {
          margin-top: -0.04rem;
        }
      }
    }
  }
  li {
    position: relative;
    display: inline-block;
    color: #666;
    &:first-child a {
      padding-left: 0;
    }
    &:last-child {
      a {
        color: #666;
      }
    }
    i {
      position: relative;
      padding: 0 3px 0 3px;
      margin-top: -4px;
      font-size: 12px;
      vertical-align: middle;
      display: inline-block;
    }
    a {
      font-size: 14px;
      color: #727171;
      padding: 0 5px;
      display: inline-block;
      vertical-align: middle;
    }
    &:hover {
      a {
        text-decoration: underline;
      }
    }
    &:nth-last-child(2) {
      a {
        // text-decoration: none;
        // pointer-events: none;
      }
    }
    &.goback {
      float: right;
      font-size: 14px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}
