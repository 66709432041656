//表单
.mainform {
  position: relative;
  $error-color: #ff3737;
  $li-h: 28px;
  .mainformtitle {
    position: relative;
    font-size: 20px;
    color: $color-main;
    text-align: center;
  }
  .tip {
    display: inline-block;
    width: 100%;
    padding-left: 162px;
    color: #a0a0a0;
    line-height: $li-h / 2;
    text-align: left;
    vertical-align: middle;
  }
  .tip.error {
    display: block;
    color: $error-color;
  }
  .headbox {
    text-align: center;
    .imgstyle {
      $w: 100px;
      display: inline-block;
      position: relative;
      width: $w;
      height: $w;
      border-radius: 50%;
      line-height: $w;
      background-color: #d2d2d2;
      .iconfont {
        font-size: 50px;
        color: #fff;
      }
      &:before {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
      &:after {
        display: none;
        content: '更换头像';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: #fff;
        font-size: 14px;
      }
      &.edit:hover {
        cursor: pointer;
        &:before,
        &:after {
          display: block;
        }
      }
    }
  }
  & > form > ul {
    position: relative;
    margin: 30px auto 0;
    z-index: 1;
    & > li {
      //   $li-h: 28px;
      $input-w: 340px;
      margin-bottom: $li-h / 2;
      font-size: 14px;
      // overflow: hidden;
      span:first-child {
        display: inline-block;
        width: 160px;
        // margin-top: 5px;
        padding-right: 3px;
        color: #737373;
        line-height: calc(#{$li-h} + 8px);
        text-align: right;
        vertical-align: top;
        .must {
          padding-right: 3px;
          color: #f00;
        }
      }
      input[type='text'],
      input[type='password'],
      input[type='date'],
      select,
      textarea {
        display: inline-block;
        height: $li-h;
        width: $input-w;
        padding: 3px;
        color: #393939;
        font-size: 14px;
        text-indent: 3px;
        border: 1px solid #dadada;
        background: #fff;
      }
      .box {
        width: $input-w;
      }
      input[type='text'].error,
      input[type='password'].error {
        border-color: $error-color;
      }
      input[type='text']:read-only {
        color: #a0a0a0;
        background-color: #f5f5f5;
      }
      textarea {
        height: 160px;
        resize: none;
      }
      .box {
        display: inline-block;
        width: $input-w;
        line-height: calc(#{$li-h} + 8px);
        text-align: left;
        vertical-align: middle;
        label {
          display: inline-block;
          margin: 0 9px;
          color: #504f4f;
          cursor: pointer;
        }
      }
      input[type='radio'],
      input[type='checkbox'] {
        margin-right: 5px;
        margin-top: -3px;
        vertical-align: middle;
      }

      &.yzm {
        input {
          width: 240px;
        }
        .btn {
          display: inline-block;
          width: 90px;
          height: $li-h;
          margin-left: 10px;
          color: #fff;
          line-height: $li-h;
          background-color: #b3b3b3;
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }
  }
  .info {
    margin: 10px 0;
    font-size: 16px;
    color: $color-main;
    text-align: left;
  }
}
