@import '~@/platform/desktop/styles/set.scss';

.openhour {
  display: flex;
  justify-content: space-between;
  height: 177px;
  padding: 12px 12px 12px;
  color: #666;
  background: url('#{$g-imgsrc}/survey/openhour-bg.png') no-repeat;
  .openhourleft {
    width: 380px;
    height: 176px;
    background: url('#{$g-imgsrc}/survey/openhour-leftbg.jpg') no-repeat;
    flex-shrink: 0;
    li {
      height: 23px;
      margin: 11px 0;
      font-size: 18px;
      color: #666666;
      text-align: center;
    }
    .opentele {
      margin-top: 23px;
      background: url('#{$g-imgsrc}/survey/tele.png') center no-repeat;
    }
    .openmail {
      background: url('#{$g-imgsrc}/survey/mail.png') center no-repeat;
    }
  }
  .openhourright {
    width: 100%;
    overflow: hidden;
    padding: 20px 80px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h3 {
      font-size: 16px;
      text-align: left;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        text-align: center;
        font-family: 'num';
        font-size: 16px;
        &:after {
          content: '~';
          position: absolute;
          top: 20px;
          right: -82px;
          font-size: 26px;
          color: #355a8b;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
        h2 {
          font-size: 40px;
        }
      }
    }
  }
}

.publictran {
  height: 403px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
  padding: 5px;
  box-sizing: border-box;
  .publicbus {
    position: relative;
    width: calc(100% - 396px);
    margin-left: 5px;
    background: url('#{$g-imgsrc}/survey/public-bg.png') center no-repeat;
    li {
      padding-left: 45px;
      margin-bottom: 45px;
      display: flex;
      flex-flow: column;
      &:first-child {
        margin-top: 55px;
      }
      &:last-child {
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        height: 75px;
        justify-content: center;
        h3 {
          color: #b1bfd1;
        }
        div {
          height: 75px;
          line-height: 75px;
        }
      }
      div {
        display: flex;
        align-items: center;
        height: 30px;
      }
      span {
        width: 24px;
        height: 24px;
        background: center no-repeat;
      }
      .subway {
        background-image: url('#{$g-imgsrc}/survey/sub.png');
      }
      .bus {
        background-image: url('#{$g-imgsrc}/survey/bus.png');
      }
      .parking {
        background-image: url('#{$g-imgsrc}/survey/parking.png');
      }
      .add {
        width: 21px;
        height: 27px;
        background-image: url('#{$g-imgsrc}/survey/add.png');
      }
      h3 {
        margin-left: 14px;
        color: #fff;
        font-size: 18px;
      }
      p {
        margin-top: 5px;
        font-size: 14px;
        color: #fff;
        padding-left: 38px;
      }
    }
  }
}

.surbox {
  padding: 3px;
  border: 1px solid #d2d2d2;
  .sur {
    padding: 28px 23px;
    border: 1px solid #e8e8e8;
    ul {
      li {
        margin-bottom: 6px;
        em {
          width: 18px;
          height: 18px;
          display: inline-block;
          background: #c7a76d;
          line-height: 19px;
          text-align: center;
          font-style: italic;
          color: #fff;
          font-size: 14px;
          font-family: 'fangzheng';
        }
        p {
          display: inline-block;
          font-size: 16px;
          margin-left: 8px;
          color: #666666;
        }
      }
    }
  }
}

.visitnotes {
  background: #eee url('#{$g-imgsrc}/survey/visitnotes-bg.jpg') right bottom
    no-repeat;
  .cont {
    display: flex;
    padding: 10px 30px 40px;
    color: #6f6f6f;
    line-height: 30px;
    .left {
      margin-right: 57px;
      width: 500px;
    }
    .right {
    }
    h3 {
      position: relative;
      margin-top: 30px;
      margin-bottom: 5px;
      padding-left: 20px;
      font-size: 16px;
      color: $color-main;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background: url('#{$g-imgsrc}/survey/dot.png') center no-repeat;
      }
    }
    p {
      position: relative;
      font-size: 14px;
      padding-left: 20px;
      &:before {
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        width: 4px;
        height: 4px;
        border: 1px solid #999;
        border-radius: 50%;
      }
      &.lianjie {
        &:before {
          width: 0;
          height: 0;
          border: none;
        }
        a {
          color: #355a8b;
        }
      }
    }
  }
}
