@import '~@/platform/desktop/styles/set.scss';
$section-margin: 30px;
$section-width-short: $index-allcenter-width-lg * 0.18;
$section-width-long: $index-allcenter-width-lg - $section-margin -
  $section-width-short;
$box-height: 130px;
$box-height2: 150px;
$index-allcenter-w: $index-allcenter-width-lg;
$index-text-color: #666;

.indexbody {
  position: relative;
  color: #666;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    height: 400px;
    bottom: 0;
    background: url('#{$g-imgsrc}/index/index-bottom-bg.png') no-repeat center
      bottom;
  }
}

.index-cont {
  .allcenter {
    width: $index-allcenter-w;
  }
}
section {
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $box-height;
    & > a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .fstitle {
    padding-bottom: 10px;
    .name {
      font-size: 20px;
      letter-spacing: 1px;
      font-family: 'fangzheng';
    }
  }
}
.index-box {
  display: flex;
  justify-content: space-between;
  margin-top: $section-margin * 1.4 - 2;
  font-size: 0;
}

.index-leftpart {
  width: $section-width-long;
}
.index-toppart {
  display: flex;
  justify-content: space-between;
}

.index-survey {
  .box {
    a:first-child + a {
      width: 55%;
    }
  }
  .opengovbox {
    background: url('#{$g-imgsrc}/index/opengovbg.jpg') no-repeat center / cover;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    p {
      font-size: 15px;
      min-height: 44px;
      color: #fff;
      margin-top: 5px;
    }
    i {
      margin-top: 12px;
      display: block;
      height: 7px;
      background: url('#{$g-imgsrc}/index/opengov-arrow.png') no-repeat center;
    }
  }
  /* .cont {
    height: 100%;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.5;
    text-indent: 2em;
    color: $index-text-color;
    text-align: justify;
    @include ellipsis(7);
  } */
}

.index-news {
  margin: 0 $section-margin;
  flex-shrink: 1;
  overflow: hidden;
  width: 100%;
  .fstitle {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background-color: #dedfe2;
    }
    span {
      position: relative;
      display: inline-block;
      margin-right: $section-margin / 2;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -12px;
        width: 100%;
        height: 2px;
        z-index: 1;
      }
      &.active {
        &:after {
          background-color: $color-main;
        }
      }
    }
    .more {
      display: inline-block;
      font-size: 13px;
      color: $index-text-color;
      &:hover {
        color: $color-secondary;
      }
    }
  }
  .box {
    align-items: flex-end;
  }
  .list {
    $fs: 14px;
    $mt: 11px;
    $lh: 1;
    $n: 5;
    width: 100%;
    height: ($fs * $lh + $mt) * $n;
    .item {
      margin-top: $mt;
    }
    .link {
      display: flex;
      justify-content: space-between;
      line-height: $lh;
    }
    .name,
    .date {
      font-size: $fs;
    }
    .name {
      width: calc(100% - 100px);
      color: $index-text-color;
      @include ellipsis();
    }
    .date {
      color: #c9c9c9;
      text-align: justify;
    }
    .item:hover {
      .name,
      .date {
        color: $color-secondary;
      }
    }
  }
}
.index-survey,
.index-exhibition,
.index-combination .list .item:first-child,
.index-combination .list .item:last-child {
  width: $section-width-long * 0.3;
  flex-shrink: 0;
}
.index-survey,
.index-exhibition {
  flex-shrink: 0;
  .imgstyle {
    width: 100%;
    height: 100%;
  }
  .box {
    a:first-child + a {
      margin-left: 5px;
    }
  }
}

.index-combination {
  $list: 'spzy', 'xscb', 'cpyj', 'wwdc';
  margin-top: $section-margin;
  height: $box-height2 * 1.1;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: no-repeat left center / auto 100%;
  }
  .list {
    height: 100%;
    display: flex;
    .item {
      position: relative;
      height: 100%;
      transition: width 0.4s;
    }
    .item:nth-child(2),
    .item:nth-child(3) {
      width: $section-width-long * 0.2;
    }
    &:hover {
      .item {
        width: $section-width-long * 0.2166 !important;
      }
    }
    &:hover {
      .item:hover {
        width: $section-width-long * 0.35 !important;
      }
    }

    a {
      display: block;
      height: 100%;
    }
    @each $value in $list {
      .#{$value} {
        .bg {
          background-image: url('#{$g-imgsrc}/index/com-'+$value+'.jpg');
        }
      }
    }
  }
}

.index-guide {
  display: flex;
  flex-flow: column;
  width: $section-width-short;
  .box {
    justify-content: space-between;
    align-items: normal;
    flex-flow: column;
    height: 100%;
    padding: $section-margin - 8 $section-margin;
    font-size: 14px;
    box-sizing: border-box;
    background-color: rgba(210, 210, 210, 0.28);
  }
  .time {
    $h: 40px;
    $left: 25px;
    text-align: center;
    &-p {
      display: inline-block;
      position: relative;
      font-family: 'num';
      line-height: $h;
      color: $color-main;
      font-size: 30px;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 1px;
        width: 13px;
        height: $h;
        background: no-repeat center / 100% auto;
      }
      &:before {
        left: -$left;
        background-image: url('#{$g-imgsrc}/index/guide-time-left.png');
        background-position-y: top;
      }
      &:after {
        right: -$left;
        background-image: url('#{$g-imgsrc}/index/guide-time-right.png');
        background-position-y: bottom;
      }
    }
  }
  .cont {
    margin: 10px 0;
    width: 100%;
    line-height: 1.25;
    text-align: justify;
  }
  .info {
    width: 100%;
    .iconfont {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      font-size: 18px;
      color: $color-secondary;
    }
  }
  .list {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    width: 100%;
    .item {
      text-align: center;
      a {
        color: #666666;
      }
    }
    .iconfont {
      $w: 42px;
      display: block;
      width: $w;
      height: $w;
      margin-bottom: 3px;
      line-height: $w;
      font-size: 24px;
      color: #fff;
      border-radius: 50%;
      background-color: $color-main;
    }
  }
}

$index-footer-pd: 20px;
.index-footer {
  font-size: 14px;
}
.index-footer-toppart {
  display: flex;
  border-bottom: 1px solid #dedfe2;
}
.index-friendlink {
  width: $section-width-long * 0.7;
  padding: $index-footer-pd;
  padding-left: 0 !important;
  flex-shrink: 0;
  border-right: 1px solid #dedfe2;
  box-sizing: border-box;
  ul {
    display: inline-block;
    li {
      display: inline-block;
      margin: 0 12px;
      a {
        color: $index-text-color;
      }
    }
  }
  ul + a {
    padding-left: 30px;
    color: $index-text-color;
  }
  a {
    transition: color 0.3s;
  }
  a:hover {
    color: $color-secondary;
  }
}
.index-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $index-footer-pd;
  padding-right: 0 !important;
  width: $section-width-long * 0.3;
  box-sizing: border-box;
  p {
    img {
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
}
.index-contact {
  .box1,
  .box2 {
    display: flex;
    p {
      position: relative;
      cursor: pointer;
      .cont {
        display: none;
        position: absolute;
        width: 340px;
        font-size: 14px;
        color: #fff;
        line-height: 30px;
        border-radius: 4px;
        background-color: #806b45;
        left: 120%;
        top: -20%;
        text-indent: 1em;
        z-index: 2;
        &::before {
          left: -10px;
          content: '';
          height: 0;
          width: 0;
          border: 5px solid transparent;
          border-right: 5px dashed #806b45;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      &:hover {
        .cont {
          display: block;
        }
      }
    }
  }
  .copy {
    margin-right: 15px;
    .cont {
      width: 405px !important;
    }
  }
  .wechat {
    margin-right: 15px;
  }
  // .wechat,
  // .weibo {
  //   position: relative;
  //   cursor: pointer;
  //   .icon {
  //     width: 20px;
  //     height: 20px;
  //     display: block;
  //     background: no-repeat center / contain;
  //   }
  //   .imgbox {
  //     $w: 120px;
  //     display: none;
  //     position: absolute;
  //     top: -$w - 25;
  //     left: 50%;
  //     width: $w;
  //     height: $w + 2;
  //     padding: 5px;
  //     transform: translateX(-50%);
  //     border: 1px solid #d2d2d2;
  //     background-color: #fff;
  //     img {
  //       width: 100%;
  //     }
  //   }
  //   &:hover .imgbox {
  //     display: block;
  //   }
  // }
  // .wechat .icon {
  //   background-image: url('#{$g-imgsrc}/index/icon-weixin.png');
  // }
  // .weibo .icon {
  //   background-image: url('#{$g-imgsrc}/index/icon-weibo.png');
  // }
}

.index-footer-bottompart {
  display: flex;
  justify-content: center;
}
.index-copyright {
  display: flex;
  align-items: center;
  padding: 10px 0;
  a {
    color: #666;
  }
  p {
    margin: 0 7px;
  }
  img {
    vertical-align: middle;
  }
  .beian {
    img {
      margin-right: 3px;
      margin-top: -3px;
    }
  }
  .sydw {
    img {
      width: 37px;
      margin-right: -5px;
    }
  }
}
// 页脚统计图标
.websum {
  position: absolute;
  left: 50%;
  margin-left: 310px;
  bottom: 17px;
}
@media (max-width: $screen-lg-min) {
  $section-margin: 25px;
  $section-width-short: $index-allcenter-width-md * 0.18;
  $section-width-long: $index-allcenter-width-md - $section-margin -
    $section-width-short;
  $box-height: 115px;
  $box-height2: 125px;
  $index-allcenter-w: $index-allcenter-width-md;
  .index-cont {
    .allcenter {
      width: $index-allcenter-w;
    }
  }
  section {
    .box {
      height: $box-height;
    }
    .fstitle {
      padding-bottom: 10px;
      .name {
        font-size: 18px;
      }
    }
  }
  .index-box {
    margin-top: $section-margin * 1.4;
  }
  .index-leftpart {
    width: $section-width-long;
  }
  /* .index-survey {
    width: $section-width-long * 0.37;
    .imgstyle {
      width: 39.2%;
      margin-right: $section-margin * 0.7;
    }
    .cont {
      font-size: 14px;
      @include ellipsis(6);
    }
  } */
  .index-survey {
    .opengovbox {
      padding: 7px;
      p {
        margin-top: 0;
        font-size: 13px;
        min-height: 38px;
      }
      i {
        margin-top: 8px;
      }
    }
  }

  .index-news {
    margin: 0 $section-margin;
    .fstitle {
      span {
        margin-right: $section-margin / 2;
      }
      .more {
        font-size: 12px;
      }
    }
    .list {
      $fs: 13px;
      $mt: 9px;
      $lh: 1;
      $n: 5;
      height: ($fs * $lh + $mt) * $n;
      .item {
        margin-top: $mt;
      }
      .link {
        line-height: $lh;
      }
      .name,
      .date {
        font-size: $fs;
      }
      .name {
        width: calc(100% - 90px);
      }
    }
  }
  .index-survey,
  .index-exhibition,
  .index-combination .list .item:first-child,
  .index-combination .list .item:last-child {
    width: $section-width-long * 0.3;
  }
  .index-combination {
    margin-top: $section-margin;
    height: $box-height2 * 1.1;
    .list {
      .item:nth-child(2),
      .item:nth-child(3) {
        width: $section-width-long * 0.2;
      }
      &:hover {
        .item {
          width: $section-width-long * 0.2166 !important;
        }
      }
      &:hover {
        .item:hover {
          width: $section-width-long * 0.35 !important;
        }
      }
    }
  }
  .index-guide {
    width: $section-width-short;
    .box {
      padding: $section-margin - 5;
      font-size: 13px;
    }
    .time {
      $h: 38px;
      $left: 20px;
      &-p {
        line-height: $h;
        font-size: 22px;
        &:before,
        &:after {
          width: 11px;
          height: $h;
        }
        &:before {
          left: -$left;
        }
        &:after {
          right: -$left;
        }
      }
    }
    .cont {
      margin: 9px 0;
    }
    .info {
      line-height: 1.3;
      .iconfont {
        margin-right: 8;
        font-size: 16px;
      }
    }
    .list {
      margin-top: 10px;
      .iconfont {
        $w: 38px;
        width: $w;
        height: $w;
        margin-bottom: 2px;
        line-height: $w;
        font-size: 20px;
      }
    }
  }
  $index-footer-pd: 12px;
  .index-footer {
    font-size: 12px;
  }
  .index-friendlink {
    width: $section-width-long * 0.7;
    padding: $index-footer-pd;
    ul {
      li {
        margin: 0 12px;
      }
    }
    ul + a {
      padding-left: 30px;
    }
  }
  .index-contact {
    padding: $index-footer-pd;
    width: $section-width-long * 0.3;
  }
  .index-contact {
    .wechat,
    .weibo {
      .icon {
        width: 14px;
        height: 14px;
      }
    }
  }
  .index-copyright {
    .sydw {
      img {
        width: 30px;
      }
    }
  }
  .websum {
    margin-left: 273px;
    bottom: 14px;
  }
}

@media (max-width: $screen-md-min) {
  $section-margin: 15px;
  $section-width-short: $index-allcenter-width-sm * 0.18;
  $section-width-long: $index-allcenter-width-sm - $section-margin -
    $section-width-short;
  $box-height: 100px;
  $box-height2: 110px;
  $index-allcenter-w: $index-allcenter-width-sm;
  .index-cont {
    .allcenter {
      width: $index-allcenter-w;
    }
  }
  section {
    .box {
      height: $box-height;
    }
    .fstitle {
      padding-bottom: 10px;
      .name {
        font-size: 18px;
      }
    }
  }
  .index-box {
    margin-top: $section-margin * 1.4;
  }
  .index-leftpart {
    width: $section-width-long;
  }
  /* .index-survey {
    width: $section-width-long * 0.37;
    .imgstyle {
      width: 39.2%;
      margin-right: $section-margin * 0.7;
    }
    .cont {
      font-size: 12px;
    }
  } */
  .index-survey {
    .opengovbox {
      padding: 4px;
      p {
        font-size: 12px;
        min-height: 36px;
      }
      i {
        margin-top: 6px;
      }
    }
  }
  .index-news {
    margin: 0 $section-margin;
    .fstitle {
      span {
        margin-right: $section-margin / 2;
      }
    }
    .list {
      $fs: 12px;
      $mt: 8px;
      $lh: 1;
      $n: 5;
      height: ($fs * $lh + $mt) * $n;
      .item {
        margin-top: $mt;
      }
      .link {
        line-height: $lh;
      }
      .name,
      .date {
        font-size: $fs;
      }
      .name {
        width: calc(100% - 80px);
      }
    }
  }
  .index-survey,
  .index-exhibition,
  .index-combination .list .item:first-child,
  .index-combination .list .item:last-child {
    width: $section-width-long * 0.3;
  }
  .index-combination {
    margin-top: $section-margin;
    height: $box-height2 * 1.1;
    .list {
      .item:nth-child(2),
      .item:nth-child(3) {
        width: $section-width-long * 0.2;
      }
      &:hover {
        .item {
          width: $section-width-long * 0.2166 !important;
        }
      }
      &:hover {
        .item:hover {
          width: $section-width-long * 0.35 !important;
        }
      }
    }
  }
  .index-guide {
    width: $section-width-short;
    .box {
      padding: $section-margin;
      font-size: 12px;
    }
    .time {
      $h: 28px;
      $left: 15px;
      &-p {
        line-height: $h;
        font-size: 18px;
        &:before,
        &:after {
          width: 10px;
          height: $h;
        }
        &:before {
          left: -$left;
        }
        &:after {
          right: -$left;
        }
      }
    }
    .cont {
      margin: 7px 0;
    }
    .info {
      line-height: 1.3;
      .iconfont {
        margin-right: 8;
        font-size: 14px;
      }
    }
    .list {
      margin-top: 9px;
      .iconfont {
        $w: 35px;
        width: $w;
        height: $w;
        margin-bottom: 1px;
        line-height: $w;
        font-size: 18px;
      }
    }
  }
  $index-footer-pd: 6px;
  .index-footer {
    font-size: 12px;
  }
  .index-friendlink {
    width: $section-width-long * 0.7;
    padding: $index-footer-pd;
    ul {
      li {
        margin: 0 12px;
      }
    }
    ul + a {
      padding-left: 30px;
    }
  }
  .index-contact {
    padding: $index-footer-pd + 2 $index-footer-pd;
    width: $section-width-long * 0.3;
  }
  .index-contact {
    .phone {
      margin-right: 0;
    }
    .wechat,
    .weibo {
      .icon {
        width: 12px;
        height: 12px;
      }
    }
  }
}
