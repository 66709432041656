@import '~@/platform/desktop/styles/set.scss';
body {
  background: url('#{$g-imgsrc}/common/bg.jpg') repeat-y top center;
}
//内页主体部分
.inner-cont {
  position: relative;
  margin: 0 auto;
  background: $inner-body-bgcolor url('#{$g-imgsrc}/common/inner-bg.jpg') repeat
    top center;
  .allcenter {
    width: $inner-allcenter-inner;
    min-height: 500px;
    padding: 30px
      calc((#{$inner-allcenter-out} - #{$inner-allcenter-inner}) / 2) 90px;
    background: $inner-cont-bgcolor;
  }
}

.main-title {
  $h: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 25px 0 25px;
  padding-top: 30px;
  height: $h + 2px;
  text-align: center;
  line-height: $h + 2px;
  h3 {
    position: relative;
    font-size: 30px;
    color: $color-main;
    font-family: 'fangzheng';
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      width: 11px;
      height: $h;
      background: no-repeat center;
    }
    &:before {
      left: -20px;
      background-image: url('#{$g-imgsrc}/common/icon-left-blue.png');
    }
    &:after {
      right: -20px;
      background-image: url('#{$g-imgsrc}/common/icon-right-blue.png');
    }
  }
  .more {
    position: absolute;
    right: 0;
    @include cl-fs-linh(#595757, 14px, 1.5);
    i {
      display: block;
      @include cl-fs-linh($color-main, 34px, 0.4);
    }
  }
}

.breadcrumb + .main-title {
  margin-top: 0;
  padding-top: 10px;
}

//imglist
@for $i from 2 through 5 {
  .global-imglist-#{$i} {
    $mt: 35px;
    $mr: 20px;
    margin-top: -$mt;
    li {
      display: inline-block;
      width: calc((100% - (#{$i} - 1) *#{$mr}) / #{$i});
      margin-top: $mt;
      margin-right: $mr;
      vertical-align: top;
      &:nth-child(#{$i}n) {
        margin-right: 0;
      }
    }
  }
}

.common-imglist {
  @include imglist($n: 4, $imgh: 232.5px, $align: center);
  li {
    width: calc((100% - 170px) / 4);
    box-shadow: 0 0 10px #c6baba;
    padding: 12px 10px;
    .imgstyle {
      //padding: 12px 10px;
    }
  }
}

.global-thirdmenu {
  display: flex;
  justify-content: flex-start;
  $h: 35px;
  $w: 7px;
  li {
    position: relative;
    min-width: 95px;
    height: $h;
    margin-left: $w;
    margin-right: 30px;
    padding: 0 18px;
    @include cl-fs-linh(#434343, 14px, $h);
    text-align: center;
    cursor: pointer;
    // @include transition();
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    &:hover {
      color: #5594b5;
    }
  }
}
//公用按钮
.golobal-btn {
  display: inline-block;
  color: #fff;
  border-radius: $border-radius-base;
  background: #ccaa76;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
  &-xs {
    padding: 2px 8px;
  }
  &-sm {
    width: 80px;
    height: 32px;
    line-height: 32px;
  }
  &-md {
    width: 100px;
    height: 40px;
    line-height: 40px;
  }
  &-lg {
    padding: 10px 50px;
  }
  &-block {
    display: block;
  }
  &-cancel {
    background-color: $color-default;
  }
  &-default {
    border: 1px solid #ddd;
    background-color: #fff;
    color: $color-default;
  }
  &:hover {
    opacity: 0.9;
  }
}
// 分享
.detail-share {
  height: 42px;
  $pd: 15px;
  & > span {
    float: right;
    height: 100%;
    width: 60px;
    text-align: right;
    @include cl-fs-linh(#565656, 14px, 42px);
  }
  .social-share {
    position: relative;
    float: right;
    margin-right: -9px;
    padding: 0 0 0 5px;
    border-radius: 5px;
    li {
      display: inline-block;
    }
    & > a {
      margin: 4px 10px;
    }
  }
}

.threed {
  width: 40px;
  height: 40px;
  //   background: $color-main url(~@/assets/images/desktop/collection/threed.png)
  //     no-repeat center;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 5;
  border-radius: 50%;
  cursor: pointer;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    // background: url(~@/assets/images/desktop/collection/threed-rotate.png)
    //   no-repeat center;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.6s ease-out;
  }
  &:hover:before {
    transform: rotate(180deg);
  }
}

.title-img {
  border-top: 1px solid #c1d0db;
  padding-top: 35px;
  font-size: 16px;
  color: #727171;
  line-height: 1.5;
  font-weight: 700;
  text-align: left;
}

.img-detail {
  //   .title-img {
  //     font-size: 16px;
  //     color: #727171;
  //     line-height: 1.5;
  //     font-weight: 700;
  //     margin-top: 20px;
  //   }
  .common-imglist {
    margin-top: -20px;
    margin-bottom: 40px;
    li {
      width: calc((100% - 120px) / 4);
      padding: 0;
      margin-right: 40px;
      box-shadow: none;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .cont {
        padding-bottom: 12px;
        background-color: #efefef;
      }
    }
  }
}
.enlarge {
  position: absolute;
  right: 0;
  top: 13px;
  cursor: pointer;
}
.imgstyle {
  &.use-img {
    .enlarge {
      img {
        background-color: transparent;
        width: 80%;
      }
    }
  }
}
.collectiondetail {
  .imgstyle {
    &.use-img {
      cursor: auto;
    }
  }
}

.innerbody {
  .websum {
    margin-left: 226px !important;
    bottom: 14px !important;
  }
}

.wapbody {
  min-width: auto !important;
  width: 1340px !important;
  overflow-x: hidden !important;
  min-height: 2700px !important;

  .cb-slideshowbox,
  .cb-slideshowbox .item {
    height: 40vh;
  }
}
