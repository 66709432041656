@import '~@/platform/desktop/styles/set.scss';

footer {
  background: #355a8b;
  .allcenter {
    width: 1250px;
  }

  .secpart {
    padding: 40px 0;
    .allcenter {
      display: flex;
      justify-content: space-between;
    }
  }
  .secleft {
    position: relative;
    padding-top: 37px;
    padding-right: 12px;
    line-height: 28px;
    li {
      position: relative;
      a {
        display: block;
        font-size: 14px;
        color: #fff;
      }
      &:after {
        content: '>';
        position: absolute;
        top: -1px;
        right: -12px;
        color: #fff;
      }
      &.copy {
        .cont {
          width: 405px;
        }
      }
      .cont {
        display: none;
        position: absolute;
        left: 140%;
        top: 50%;
        width: 340px;
        transform: translateY(-50%);
        @include cl-fs-linh(#fff, 14px, 30px);
        border-radius: 4px;
        background-color: #806b45;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
          @include triangle(left, 5px, #806b45);
        }
      }
      &:hover {
        .cont {
          display: block;
        }
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 29px;
      background: no-repeat center;
    }
  }

  .fsmenu {
    width: 794px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: -50px;
      width: 1px;
      height: 85%;
      background-color: #fff;
    }
    & > li {
      float: left;
      height: 44px;
      line-height: 22px;
      width: 470px;
      &.right {
        float: right;
        width: 325px;
      }
      &:first-child {
        display: none;
      }
      &:nth-child(5) {
        margin-top: -132px;
      }
      &:nth-child(6) {
        margin-top: -88px;
      }
      &:nth-child(7) {
        margin-top: -44px;
      }
      & > a {
        display: block;
        float: left;
        font-size: 16px;
        color: #fff;
        margin-right: 10px;
        &:after {
          content: '>';
        }
      }
      ul {
        float: left;
        width: calc(100% - 100px);
        li {
          float: left;
          padding: 0 7px;
          line-height: 22px;
          a {
            color: #b1bfd1;
          }
        }
        .more5 {
          line-height: 22px;
        }
      }
    }
  }

  .ewmbox {
    width: calc(100% - 830px - 88px);
    li {
      display: inline-block;
      margin-right: -10px;
      margin-bottom: 9px;
      &:first-child {
        display: flex;
        justify-content: space-between;
        .weibo {
          .icon {
            margin-left: -25px;
          }
          .imgbox {
            left: -38px !important;
          }
          margin-right: 30px;
        }
        .webox {
          width: 65px;
          height: 100%;
          margin-right: 20px;
          display: flex;
          li {
            height: 21px;
            width: 25px;
            p {
              margin-right: 80px;
            }
          }
        }
      }
      p {
        float: left;
      }
      span {
        display: block;
        float: left;
        width: 24px;
        margin-right: 5px;
        a {
          display: block;
          height: 20px;
          background: url(~@/assets/images/desktop/common/wb.png) no-repeat
            center / cover;
        }
        i {
          display: block;
          height: 20px;
          background: url(~@/assets/images/desktop/common/wx.png) no-repeat
            center / cover;
        }
      }
      p {
        color: #fff;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .thirdpart {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 15px 0;
    color: #b1bfd1;
    background-color: rgba(#000, 0.3);
    .pbox {
      height: 20px;
      p {
        float: left;
        margin-right: 12px;
        & > a {
          color: #b1bfd1;
          padding-left: 5px;
        }
      }

      span {
        display: block;
        float: left;
        a {
          height: 23px;
          width: 20px;
          display: inline-block;
          background: url(~@/assets/images/desktop/common/blue.png) no-repeat
            center / cover;
          margin-top: -3px;
        }
      }
    }
  }
}

.selectTitle {
  width: 326px;
  height: 40px;
  border: 1px solid #fff;
  position: relative;
  cursor: pointer;
  position: relative;
  p {
    line-height: 40px;
    margin-right: 0;
    margin-left: 16px;
  }
  .downlogo {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(90deg);
    color: #fff;
    transition: all ease-in-out 0.5s;
    &.active {
      transform: rotate(-90deg);
    }
  }
  .selectList {
    width: 326px;
    height: 0;
    position: absolute;
    top: 40px;
    left: -1px;
    border: 1px solid #fff;
    border-top: none;
    overflow-x: hidden;
    transition: all ease-in-out 0.5s;
    &.active {
      height: 84px;
    }
    li {
      display: block;
      height: 20px;
      padding: 0 0 1px 15px;
      a {
        color: #fff;
      }
    }
  }
}
