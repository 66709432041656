//分页
.mypager {
  $fs: 16px;
  $c: rgba(0, 0, 0, 0.65);
  $bc: #39678d;
  $bcc: #fff;
  margin-top: 70px;
  font-size: 0;
  color: $c;
  text-align: center;
  ul,
  .pager-jump {
    display: inline-block;
    vertical-align: middle;
  }
  a {
    color: $c;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  ul {
    li {
      cursor: pointer;
      display: inline-block;
      min-width: 32px;

      height: 32px;
      margin: 0 8px;
      border-radius: 50%;
      font-size: $fs;
      text-align: center;
      line-height: 32px;
      transition: border 0.3s;
      &:hover {
        background-color: $bc;
        a {
          color: $bcc;
        }
      }
      &.active {
        background-color: $bc;
        color: $bcc;
        a {
          color: $bcc;
        }
      }
      &.page-btn:hover {
        background-color: inherit;
        a {
          color: #4c7693;
        }
      }
      &.disable {
        cursor: default;
        a {
          color: #d1d1d1;
        }
        &:hover {
          a {
            color: #d1d1d1;
          }
        }
      }
    }
  }
  .pager-jump {
    font-size: 0;
    input,
    button {
      height: 30px;
      margin: 0 5px;
      font-size: 14px;
      border-radius: 4px;
      vertical-align: middle;
      box-sizing: border-box;
    }
    input {
      width: 40px;
      padding: 4px 5px;
      border: 1px solid #cecece;
      color: $c;
      text-align: center;
    }
    button {
      padding: 4px 14px;

      color: #fff;
      background: $bc;
      cursor: pointer;
    }
  }
}
