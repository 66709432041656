@import '~@/platform/desktop/styles/set.scss';
#app {
  overflow-x: hidden;
}
body {
  overflow-y: auto;
  //   overflow-x: hidden;
}

header {
  $header-h: 76px;
  $secmenu-h: 60px;
  position: relative;
  z-index: 7;
  height: $header-h;
  line-height: $header-h;
  text-align: center;
  font-family: 'fangzheng';
  .allcenter {
    position: relative;
    display: flex;
    width: $inner-allcenter-out !important;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }
  .logo {
    width: 199px;
    height: 41px;
    background: url('#{$g-imgsrc}/common/logo.png') no-repeat;
  }
  $border-w: 3px;
  $border-color: rgba(255, 255, 255, 0.4);
  .rightbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .fsmenu {
    // $fsmenu-w: 860px;
    // $n: 7;
    display: flex;
    // width: $fsmenu-w;
    height: $header-h;

    justify-content: space-around;

    & > li {
      position: relative;
      //   width: $fsmenu-w / $n;
      cursor: pointer;
      & > a {
        $le: 1px;
        display: block;
        height: 100%;
        padding: 0 20px;
        @include cl-fs-linh(#666, 18px, $header-h);

        letter-spacing: $le;
        text-indent: $le;
      }
      &.more5 {
        .secmenu {
          //   width: 800px;
        }
      }
      &.active > a {
        background-color: $color-main;
        color: #fff !important;
      }
      &:hover > a {
        color: #4677b7;
      }
      &.nochild {
        &:hover .secmenu {
          height: 0;
          overflow: inherit;
          li {
            opacity: 0;
          }
        }
      }
      &:last-child {
        &:hover .secmenu {
          height: 0;
          overflow: inherit;
          li {
            opacity: 0;
          }
        }
      }
      &:hover .secmenu {
        height: $secmenu-h;
        overflow: inherit;
        li {
          opacity: 1;
        }
      }
      &.nochild:hover {
        a:after {
          display: none !important;
        }
        .secmenubox {
          display: none;
        }
      }
    }
  }

  .secmenu {
    background: url('#{$g-imgsrc}/common/menu-bg.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: $header-h;
    left: 50%;
    width: 1500px;
    transform: translateX(-50%);
    transition: all 0.3s ease-out 0s;
    height: 0;
    overflow: hidden;
    li {
      display: inline-block;
      opacity: 0;
      height: $secmenu-h;
      line-height: $secmenu-h;
      transition: all 0.5s ease-in 0.2s;
      vertical-align: top;
      &.active {
        a {
          color: $color-main;
        }
      }
    }
    a {
      display: inline-block;
      margin: 0 14px;
      padding: 0 6px;
      font-size: 16px;
      color: #fff;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        color: $color-main;
      }
    }
  }
  .quickmenu {
    padding-right: 20px;
    display: inline-block;
    border-left: 1px solid #dcdcdc;
    height: $header-h;
    & > li {
      position: relative;
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      i {
        position: relative;
        display: inline-block;
        font-size: 22px;
        color: #666;
      }
    }
  }
  .secmenu-bg {
    position: absolute;
    top: $header-h;
    left: 0;
    width: 100%;
    height: 0;
    //background-color: rgba(#868d97, 0.7);
    //background: url('#{$g-imgsrc}/common/menu-bg.png') no-repeat;
    transition: height 0.5s;
    &.active {
      height: $secmenu-h;
    }
  }
}

@media (max-width: $screen-lg-min) {
  header {
    .allcenter {
      width: $index-allcenter-width-md;
    }
  }
}

@media (max-width: $screen-md-min) {
  header {
    .allcenter {
      width: $index-allcenter-width-sm;
    }
  }
}

.inner-banner {
  line-height: 0;
  background: url('#{$g-imgsrc}/common/inner-banner.jpg');
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
